import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { GroupsType } from '@features/groups';
import { fetchGetTemplateStories } from '@features/storyTemplates';
import { usePrevious } from '@hooks';
import { fetchGetStories } from '../storiesSlice';

export const useFetchStories = (params: {
  type: GroupsType;
  appId?: string;
  groupId?: string;
  templateId?: string;
  selectedLayersGroupId?: string;
  stopFetching?: boolean;
}) => {
  const { type, appId, groupId, templateId, stopFetching, selectedLayersGroupId } = params;

  const dispatch = useAppDispatch();
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);
  const groupStatus = useSelector((store: RootState) => store.groups.status);
  const onboardingStatus = useSelector((store: RootState) => store.onboardings.status);
  const templateStatus = useSelector((store: RootState) => store.templates.status);
  const storiesStatus = useSelector((store: RootState) => store.stories.status);
  const templateStoriesStatus = useSelector((store: RootState) => store.templateStories.status);
  const appLoadingStatus = useSelector((store: RootState) => store.appManager.status);
  const prevLocale = usePrevious(currentLocale);

  const getGroupLoadingStatus = () => {
    switch (type) {
      case GroupsType.GROUP:
        return groupStatus;
      case GroupsType.ONBOARDING:
        return onboardingStatus;
      case GroupsType.TEMPLATE:
        return templateStatus;
      default:
        return '';
    }
  };

  const groupLoadingStatus = getGroupLoadingStatus();
  const storiesLoadingStatus = type === GroupsType.TEMPLATE ? templateStoriesStatus : storiesStatus;

  useEffect(() => {
    if (
      groupLoadingStatus !== 'loaded' ||
      appLoadingStatus !== 'loaded' ||
      stopFetching ||
      !currentLocale
    )
      return;

    if (
      storiesLoadingStatus !== 'pending' ||
      (currentLocale && prevLocale && currentLocale !== prevLocale)
    ) {
      if (type === GroupsType.TEMPLATE && templateId) {
        dispatch(fetchGetTemplateStories({ templateId }));
      } else if (appId && groupId) {
        dispatch(fetchGetStories({ appId, groupId, selectedLayersGroupId }));
      }
    }
  }, [
    groupId,
    appId,
    currentLocale,
    prevLocale,
    groupLoadingStatus,
    appLoadingStatus,
    stopFetching,
    templateId
  ]);
};
