import { WidgetsTypes } from '@storysdk/react';
import { SizePresets } from './groups';

export const KB_IN_MB = 1024;
export const MAX_FILE_SIZE_MB = 15;
export const MAX_VIDEO_DURATION_SEC = 30;
export const MAX_GROUP_AVATAR_SIZE_MB = 5;
export const MAX_GROUP_AVATAR_SIZE_KB = MAX_GROUP_AVATAR_SIZE_MB * KB_IN_MB;
export const MAX_ANSWER_IMAGE_SIZE_MB = 2;
export const MAX_ANSWER_IMAGE_SIZE_KB = MAX_ANSWER_IMAGE_SIZE_MB * KB_IN_MB;
export const MAX_FILE_SIZE_KB = MAX_FILE_SIZE_MB * KB_IN_MB;
export const SCALE_INDEX = 1;
export const REDUCE_INDEX = 3;
export const STORY_WIDTH = 360;
export const STORY_HEIGHT = 640;
export const STORY_HEIGHT_LARGE = 780;

export const STORY_CENTER = {
  x: STORY_WIDTH / 2,
  y: STORY_HEIGHT / 2
};

export const DEFAULT_WIDGET_PARAMS = {
  width: 100,
  height: 100,
  x: 100,
  y: 100
};
export const STORY_POSITION = {
  x: 0,
  y: 0,
  rotate: 0,
  width: STORY_WIDTH,
  height: STORY_HEIGHT,
  center: STORY_CENTER
};

export const COPY_WIDGET_DEVIATION = 20;
export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const MOCK_STORY_STYLE = {
  width: 234,
  height: 416,
  borderRadius: 12
};

export enum SizePresetKeys {
  IphoneLarge = 'IphoneLarge',
  IphoneSmall = 'IphoneSmall'
}

export const SIZE_PRESETS: SizePresets = {
  [SizePresetKeys.IphoneLarge]: {
    title: SizePresetKeys.IphoneLarge,
    width: STORY_WIDTH,
    height: STORY_HEIGHT_LARGE,
    isDefault: true
  },
  [SizePresetKeys.IphoneSmall]: {
    title: SizePresetKeys.IphoneSmall,
    width: STORY_WIDTH,
    height: STORY_HEIGHT
  }
};

export const SYNC_TO_LOCALES_TEXT_WIDGETS_LIMITS = {
  [WidgetsTypes.TEXT]: {
    text: true
  },
  [WidgetsTypes.SWIPE_UP]: {
    text: true
  },
  [WidgetsTypes.CLICK_ME]: {
    text: true
  },
  [WidgetsTypes.CHOOSE_ANSWER]: {
    text: true,
    answers: {
      title: true
    }
  },
  [WidgetsTypes.QUESTION]: {
    question: true,
    confirm: true,
    decline: true
  },
  [WidgetsTypes.TIMER]: {
    text: true
  },
  [WidgetsTypes.TALK_ABOUT]: {
    text: true
  },
  [WidgetsTypes.SLIDER]: {
    text: true
  },
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWERS]: {
    title: true,
    answers: {
      title: true
    }
  },
  [WidgetsTypes.QUIZ_ONE_ANSWER]: {
    title: true
  },
  [WidgetsTypes.QUIZ_OPEN_ANSWER]: {
    title: true
  },
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE]: {
    title: true,
    answers: {
      title: true
    }
  },
  [WidgetsTypes.QUIZ_RATE]: {
    title: true
  }
};
