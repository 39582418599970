import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormGroup } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import { Calendar, Input } from '@components';
import './Datepicker.scss';
import { useVisible } from '@hooks';

const CLASS_NAME = 'Datepicker';

type RangeType = {
  from: Date;
  to: Date;
};

type PropsType = {
  id: string;
  label: string;
  lang?: string;
  value: Date | RangeType | null;
  withTime?: boolean;
  onChange: (params: { date: Date }) => void;
  theme: 'dark' | 'light';
  className?: string;
  dropdownPosition?: 'left' | 'right';
  type?: 'single' | 'range';
  handleOpen?: (isOpen: boolean) => void;
};

export const Datepicker = ({
  id,
  label,
  value,
  onChange,
  lang = 'en',
  withTime,
  theme = 'dark',
  className = '',
  dropdownPosition = 'right',
  type = 'single',
  handleOpen
}: PropsType) => {
  const datepickerRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { ref, isVisible, setIsVisible } = useVisible(false);

  useEffect(() => {
    if (handleOpen) {
      handleOpen(isVisible);
    }
  }, [isVisible, handleOpen]);

  const inputValue = useMemo(() => {
    let formatedValue;

    if (value) {
      if (type === 'single' && value instanceof Date) {
        const formatter = new Intl.DateTimeFormat(lang, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });
        formatedValue = formatter.format(value);
      } else if (type === 'range') {
        const rangeVal = value as RangeType;
        const formatter = new Intl.DateTimeFormat(lang, {
          month: 'short',
          day: 'numeric',
          hour12: false
        });
        formatedValue = `${formatter.format(rangeVal.from)} - ${formatter.format(rangeVal.to)}`;
      }
    } else {
      formatedValue = 'Without date';
    }

    return formatedValue;
  }, [lang, value, type]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  const handleOutsideClick = useCallback(
    ({ target }: any) => {
      if (datepickerRef.current && !datepickerRef.current.contains(target)) {
        setIsVisible(false);
      }
    },
    [setIsVisible]
  );

  const handleOpenClick = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible, setIsVisible]);

  const handleSelect = useCallback(
    (date) => {
      onChange(date);

      if (type === 'single') {
        setIsVisible(false);
      }
    },
    [onChange, setIsVisible, type]
  );

  return (
    <div className={`${CLASS_NAME} ${className}`} ref={datepickerRef}>
      <FormGroup style={{ marginBottom: '0.25rem' }}>
        {label && <Label>{label}</Label>}
        <div className={`${CLASS_NAME}__input ${CLASS_NAME}__input_${type}`} ref={inputRef}>
          <Input
            className="box-input"
            color={theme === 'dark' ? 'black' : 'white'}
            iconClassName={`${CLASS_NAME}__icon`}
            iconName="calendar"
            iconPosition="right"
            type="text"
            value={inputValue}
            onClick={handleOpenClick}
          />
        </div>
      </FormGroup>
      {isVisible && (
        <div
          className={`${CLASS_NAME}__dropdown ${CLASS_NAME}__dropdown_${dropdownPosition}`}
          ref={ref}
        >
          <Calendar id={id} type={type} value={value} withTime={withTime} onChange={handleSelect} />
        </div>
      )}
    </div>
  );
};
