import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { DateTime } from 'luxon';
import { groupStatisticDataAdapter, storyStatisticDataAdapter } from '@features/stories/utils';
import { RootState } from '@store';
import { Story } from '../stories/types';
import { AnalitycsStoriesGroup, RangeType, AnalitycsStoriesGroupState } from './types';

const convertFromRaw = (raw: any, locale: string): Story => ({
  id: raw.id,
  createdAt: raw.created_at,
  statistic: storyStatisticDataAdapter(raw.statistic),
  widgets: raw.story_data[locale].widgets ? [...raw.story_data[locale].widgets] : [],
  background: raw.story_data[locale].background || { type: 'color', value: '#ffffff' },
  type: 'exist',
  position: raw.position,
  layerData: raw.layer_data,
  storyData: raw.story_data
});

const initialState: AnalitycsStoriesGroupState = {
  data: null,
  currentStory: null,
  status: 'idle',
  storyStatus: 'idle',
  stories: {},
  storiesLoadingStatuses: {},
  range: {
    from: null,
    to: null
  }
};

export const storiesAnalyticsGroupSlice = createSlice({
  name: 'storiesAnalyticsGroup',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<AnalitycsStoriesGroup | null>) => {
      state.data = action.payload;
      state.status = 'loaded';
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setRange: (state, action: PayloadAction<RangeType>) => {
      state.range = action.payload;
    },
    setLoadingStory: (state, action: PayloadAction<string>) => {
      state.storiesLoadingStatuses[action.payload] = true;
    },
    removeLoadingStory: (state, action: PayloadAction<string>) => {
      delete state.storiesLoadingStatuses[action.payload];
    },
    setStoryStatus: (state, action: PayloadAction<string>) => {
      state.storyStatus = action.payload;
    },
    setStory: (state, action: PayloadAction<Story>) => {
      state.stories[action.payload.id] = action.payload;
      delete state.storiesLoadingStatuses[action.payload.id];
    },
    unsetStory: (state, action: PayloadAction<Story>) => {
      delete state.stories[action.payload.id];
    },
    setCurrentStory: (state, action: PayloadAction<Story | null>) => {
      state.currentStory = action.payload;
      state.storyStatus = 'loaded';
    }
  }
});

export const fetchAnalyticsStoriesGroup = createAsyncThunk(
  'storiesAnalyticsGroup/fetchStoriesGroup',
  async (params: { appId: string; groupId: string; range: RangeType }, { dispatch, getState }) => {
    dispatch(storiesAnalyticsGroupSlice.actions.setStatus('pending'));

    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    const dateFrom = params.range.from ? DateTime.fromSeconds(params.range.from).toJSON() : '';
    const dateTo = params.range.to ? DateTime.fromSeconds(params.range.to).toJSON() : '';

    const groupResponse = await API.groups.get({ ...params, reqStatistic: true });
    const storiesResponse = await API.stories.getList({
      appId: params.appId,
      groupId: params.groupId,
      dateFrom: dateFrom ?? undefined,
      dateTo: dateTo ?? undefined,
      reqStatistic: true
    });

    if (groupResponse.data.data && storiesResponse.data.data && currentLocale) {
      const group = groupResponse.data.data;
      const stories = storiesResponse.data.data;

      dispatch(
        storiesAnalyticsGroupSlice.actions.init({
          id: group.id,
          title: group.title,
          image: group.image_url,
          settings: group.settings,
          stories: stories.map((item: any) => convertFromRaw(item, currentLocale)),
          startDate: group.start_time,
          endDate: group.end_time,
          active: group.active,
          statistic: groupStatisticDataAdapter(group.statistic)
        })
      );
    }
  }
);

export const fetchAnalyticsStory = createAsyncThunk(
  'storiesAnalyticsGroup/fetchStoriesGroup',
  async (params: { appId: string; groupId: string; storyId: string }, { dispatch, getState }) => {
    dispatch(storiesAnalyticsGroupSlice.actions.setStoryStatus('pending'));

    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    const storyResponse = await API.stories.getStory({ ...params, reqStatistic: true });

    if (storyResponse.data.data && currentLocale) {
      const story = storyResponse.data.data;

      dispatch(
        storiesAnalyticsGroupSlice.actions.setCurrentStory(convertFromRaw(story, currentLocale))
      );
    }
  }
);

export const fetchAnalyticsStoryById = createAsyncThunk(
  'storiesAnalyticsGroup/fetchAnalyticsStoryById',
  async (params: { appId: string; groupId: string; storyId: string }, { dispatch, getState }) => {
    dispatch(storiesAnalyticsGroupSlice.actions.setLoadingStory(params.storyId));

    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    const storyResponse = await API.stories.getStory({ ...params, reqStatistic: true });

    if (storyResponse.data.data && currentLocale) {
      const story = storyResponse.data.data;

      dispatch(storiesAnalyticsGroupSlice.actions.setStory(convertFromRaw(story, currentLocale)));
    }
  }
);
