import React, { useEffect, useCallback } from 'react';
import block from 'bem-cn';
import { RootState, useAppDispatch } from '@store';
import { BackgroundBlob } from '@features/stories/types';
import { useParams } from 'react-router-dom';
import { GroupsType } from '@features';
import { useCurrentStoriesSlice, useCurrentStoriesType } from '@features/stories/hooks';
import { BackgroundColorType, BackgroundFillType, MediaType } from '@storysdk/react';
import { useTranslation } from 'react-i18next';
import { BackgroundSelector } from '@modules';
import { useSelector } from 'react-redux';
import { useVisible } from '@hooks';
import { renderBackgroundStyles, renderColorFromRgba } from '../../../utils';
import './ColorPicker.scss';

const b = block('ColorPicker');

type ColorValue = { type: BackgroundColorType.COLOR; value: string };
type GradientValue = { type: BackgroundColorType.GRADIENT; value: string[] };
type TransparantValue = { type: BackgroundColorType.TRANSPARENT; value: string };
type ImageValue = { type: MediaType.IMAGE; value: string; fileId?: string; metadata?: any };
type VideoValue = { type: MediaType.VIDEO; value: string; fileId?: string; metadata?: any };

export type BackgroundType =
  | GradientValue
  | ColorValue
  | ImageValue
  | VideoValue
  | TransparantValue;

export type ColorPickerChangeParamsType = {
  value: BackgroundType;
};

type PropsType = {
  value: BackgroundType;
  light?: boolean;
  className?: string;
  availableTypes: BackgroundFillType[];
  onChange(value: BackgroundType | BackgroundBlob): void;
};

export const ImageIcon = ({ color }: { color?: string }) => (
  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M16.0001 1.16663C7.808 1.16663 1.16699 7.80772 1.16699 15.9999C1.16699 18.1946 1.64359 20.2779 2.49892 22.152L10.1958 16.5076C10.5938 16.2157 11.1452 16.2579 11.4942 16.6069L15.2915 20.4042C15.6821 20.7947 16.3152 20.7947 16.7057 20.4042L22.1062 15.0036C22.4968 14.6131 23.1299 14.6131 23.5205 15.0036L29.8468 21.33C30.4839 19.6758 30.8332 17.8787 30.8332 15.9999C30.8332 7.80772 24.1922 1.16663 16.0001 1.16663ZM0.166992 15.9999C0.166992 7.25545 7.25571 0.166626 16.0001 0.166626C24.7445 0.166626 31.8332 7.25545 31.8332 15.9999C31.8332 24.7444 24.7445 31.8333 16.0001 31.8333C7.25571 31.8333 0.166992 24.7444 0.166992 15.9999ZM15.9998 12.5928C17.8816 12.5928 19.4071 11.0672 19.4071 9.18536C19.4071 7.3035 17.8816 5.77795 15.9998 5.77795C14.1179 5.77795 12.5924 7.3035 12.5924 9.18536C12.5924 11.0672 14.1179 12.5928 15.9998 12.5928Z"
      fill={color ?? '#AFAFAF'}
      fillRule="evenodd"
    />
  </svg>
);

export const VideoIcon = ({ color }: { color?: string }) => (
  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M1.16699 15.9999C1.16699 7.80772 7.808 1.16663 16.0001 1.16663C24.1922 1.16663 30.8332 7.80772 30.8332 15.9999C30.8332 24.1922 24.1922 30.8333 16.0001 30.8333C7.808 30.8333 1.16699 24.1922 1.16699 15.9999ZM16.0001 0.166626C7.25571 0.166626 0.166992 7.25545 0.166992 15.9999C0.166992 24.7444 7.25571 31.8333 16.0001 31.8333C24.7445 31.8333 31.8332 24.7444 31.8332 15.9999C31.8332 7.25545 24.7445 0.166626 16.0001 0.166626ZM13.491 8.63184V8.64104C12.0379 8.64104 10.8599 9.87764 10.8599 11.4031V20.6098C10.8611 21.6132 11.3807 22.537 12.2171 23.0228C13.0534 23.5086 14.075 23.4801 14.8856 22.9483L21.9021 18.345C22.6722 17.8404 23.1401 16.9548 23.1401 16.0018C23.1401 15.0489 22.6722 14.1633 21.9021 13.6587L14.8856 9.05535C14.4678 8.7797 13.9845 8.63294 13.491 8.63184Z"
      fill={color ?? '#AFAFAF'}
      fillRule="evenodd"
    />
  </svg>
);

export const ColorPicker = ({ onChange, value, availableTypes, light, className }: PropsType) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { type } = useParams<{
    type: GroupsType;
  }>();

  const currentStoriesSlice = useCurrentStoriesSlice(type);
  const currentStoriesType = useCurrentStoriesType(type);

  const isPickerOpen = useSelector(
    (store: RootState) => store[currentStoriesType].editor.isPickerStoryOpen
  );
  const { ref, isVisible, setIsVisible } = useVisible(isPickerOpen);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (isPickerOpen === isVisible) return;

    setIsVisible(isPickerOpen);
  }, [isPickerOpen]);

  useEffect(() => {
    dispatch(currentStoriesSlice.actions.setIsPickerOpen(isVisible));

    if (!isVisible) {
      dispatch(currentStoriesSlice.actions.setIsPickerStoryOpen(false));
    }
  }, [isVisible, dispatch]);

  const renderBackgroundMiniature = useCallback(() => {
    switch (value.type) {
      case MediaType.IMAGE:
        return t('dashboard.stories.image');
      case BackgroundColorType.GRADIENT:
        return t('dashboard.stories.gradient');
      case BackgroundColorType.TRANSPARENT:
        return t('dashboard.stories.transparent');
      case MediaType.VIDEO:
        return `${t('dashboard.stories.video')} ${value.metadata?.duration
            ? `(${Math.round(value.metadata?.duration)}${t('dashboard.stories.s')})`
            : ''
          }`;
      default:
        return renderColorFromRgba(value.value as string).toUpperCase();
    }
  }, [value]);

  return (
    <div className={`${b().toString()} ${className ?? ''}`}>
      <button className={b('button', { light })} onClick={handleToggle}>
        {value.type !== MediaType.VIDEO && (
          <span
            className={b('color')}
            style={{
              background: renderBackgroundStyles(value)
            }}
          />
        )}
        {renderBackgroundMiniature()}
      </button>
      <div className={b('dropdown', { open: isVisible })} ref={ref}>
        <BackgroundSelector
          availableTypes={availableTypes}
          initialTab={
            value.type === BackgroundColorType.TRANSPARENT ? BackgroundColorType.COLOR : undefined
          }
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
