import React, { memo, useRef } from 'react';
import block from 'bem-cn';
import { ErrorBoundary } from 'react-error-boundary';
import { StoryHiddenMessage, StoryRenderingFallback } from '@features/stories/components';
import { SizePresetKeys, Story, StoryEditorPreviewInner } from '@features';
import { StoryEditorBackground } from '../StoryEditor/_components';
import '../StoryEditor/StoryEditor.scss';

interface StoryEditorProps {
  cardWeight: number;
  cardHeight: number;
  story: Story;
  scale: number;
  backgroundSize: {
    width: number;
    height: number;
  };
  presetHeight: number;
  presetWidth: number;
  cardTop: number;
  currentSizePreset: SizePresetKeys;
  innerClassName?: string;
  backgroundTop: number;
  isHidden: boolean;
  isOnboarding?: boolean;
  isNoSelectable?: boolean;
  onToggleStory?: () => void;
}

const PREVIEW_BORDER_RADIUS = 8;

const b = block('StoryEditor');

export const StoryEditorPreview: React.FC<StoryEditorProps> = memo(
  ({
    cardWeight,
    cardHeight,
    cardTop,
    story,
    isHidden,
    scale,
    presetHeight,
    presetWidth,
    currentSizePreset,
    backgroundSize,
    innerClassName,
    backgroundTop,
    isOnboarding,
    isNoSelectable,
    onToggleStory
  }) => {
    const storyEditorRef = useRef<HTMLDivElement>(null);

    const getProtectorTop = () => {
      if (isOnboarding || (!isOnboarding && !story.background.isFilled)) {
        return cardTop;
      }

      if (!isOnboarding) {
        return backgroundTop;
      }

      return 0;
    };

    return (
      <div
        className={b()}
        style={{
          width: cardWeight,
          height: !isOnboarding && story.background.isFilled ? backgroundSize.height : 'auto',
          cursor: !isNoSelectable ? 'pointer' : undefined
        }}
        onClick={!isNoSelectable ? onToggleStory : undefined}
      >
        <StoryEditorBackground
          backgroundSize={backgroundSize}
          backgroundTop={backgroundTop}
          borderRadius={PREVIEW_BORDER_RADIUS}
          cardHeight={cardHeight}
          cardTop={cardTop}
          isCentered={false}
          isFilled={story.background.isFilled && !isOnboarding}
          isLarge={isOnboarding}
          selectedStory={story}
        />

        <div
          className={b('protector')}
          style={{
            top: getProtectorTop()
          }}
        >
          {isHidden && <StoryHiddenMessage borderRadius={PREVIEW_BORDER_RADIUS} />}
        </div>

        <div
          className={innerClassName ?? ''}
          style={{
            width: cardWeight,
            height: cardHeight,
            top: cardTop
          }}
        >
          <ErrorBoundary FallbackComponent={StoryRenderingFallback}>
            <StoryEditorPreviewInner
              editorRef={storyEditorRef}
              presetHeight={presetHeight}
              presetWidth={presetWidth}
              scale={scale}
              widgets={story.widgets}
            />
          </ErrorBoundary>
        </div>
      </div>
    );
  }
);
