import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '@store';
import { fetchUpdateApp, fetchRemoveApp } from '@features/appsManagerSlice/appsManagerSlice';
import { DeleteAppModal } from '@features/appsManagerSlice/components/DeleteAppModal/DeleteAppModal';
import {
  AppCollaborationFeatures,
  useGetAccessByCollaboratorRole,
  useGetActionByCollaboratorRole,
  useRedirectByAccessCollaborator
} from '@features';
import { IconSaveFresh } from '@components';
import { FreshButton, FreshInput, FreshOutput } from '@components/_fresh';
import './AppSettingsTab.scss';

interface FormProps {
  title: string | null;
}

const s = block('GroupSettingsTab');

export const AppSettingsTab: React.FC = () => {
  const initialFormData = {
    title: null
  };

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormProps>(initialFormData);

  const appState = useSelector((state: RootState) => state);
  const app = appState.appManager;

  const { t } = useTranslation();
  const onActionClickCollaborator = useGetActionByCollaboratorRole();

  const isDeleteAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.ARCHIVE_APP
  });

  const isNameAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.CHANGE_APP_NAME
  });

  useRedirectByAccessCollaborator({
    feature: AppCollaborationFeatures.CHANGE_APP_SETTINGS
  });

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      title: app.current?.title ?? ''
    }));
  }, [app]);

  const handleChange = ({ fieldName, value }: any) => {
    setFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (app.current) {
      dispatch(
        fetchUpdateApp({
          id: app.current.id,
          title: formData.title ?? ''
        })
      );
    }
  };

  const handleDeleteApp = () => {
    if (app) {
      dispatch(
        fetchRemoveApp({
          id: app.current?.id,
          subscriptionId: app.current?.subscriptionId
        })
      ).then(() => {
        setDeleteOpen(false);
        history.push(`/dashboard`);
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={s('formHeader')}>
          <h1 className={s('formHeaderTitle')}>{app.current?.title}</h1>

          {isNameAvailable && (
            <FreshButton
              disabled={!formData.title}
              leftIcon={<IconSaveFresh />}
              size="md"
              text={t('form.saveSettings')}
              type="submit"
            />
          )}
        </div>

        <div className={s('formColumn')}>
          {isNameAvailable && (
            <div className={s('filedSet')}>
              <div className={s('fieldGroup')}>
                <p className={s('fieldLabel')}>{t('dashboard.apps.appName')}</p>

                <FreshInput
                  fieldName="title"
                  invalid={!formData.title}
                  placeholder={t('dashboard.apps.appNamePlaceholder')}
                  type="text"
                  value={formData.title as string}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          <div className={s('filedSet')}>
            <div className={s('fieldGroup')}>
              <p className={s('fieldLabel')}>{t('dashboard.apps.sdkToken')}</p>

              <FreshOutput
                className={`${s('descGroup')}`}
                isCopy
                value={app.current?.sdkToken ?? ''}
              />

              <p className={s('desc')}>{t('dashboard.apps.sdkTokenDesc')}</p>
            </div>
          </div>
        </div>

        {isDeleteAvailable && (
          <a
            className={cn(`${s('link')}`)}
            role="button"
            tabIndex={0}
            onClick={() =>
              onActionClickCollaborator(AppCollaborationFeatures.ARCHIVE_APP, () =>
                setDeleteOpen(true)
              )
            }
            onKeyDown={() =>
              onActionClickCollaborator(AppCollaborationFeatures.ARCHIVE_APP, () =>
                setDeleteOpen(true)
              )
            }
          >
            {t('dashboard.apps.deleteApp')}
          </a>
        )}
      </form>

      <DeleteAppModal
        handleDeleteApp={handleDeleteApp}
        isOpen={isDeleteOpen}
        title={formData.title as string}
        onClose={() => setDeleteOpen(false)}
      />
    </>
  );
};
