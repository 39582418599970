import React, { useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { ErrorBoundary } from 'react-error-boundary';
import {
  StoryHiddenMessage,
  StoryLimitedWidgets,
  StoryRenderingFallback
} from '@features/stories/components';
import {
  BackgroundBlob,
  CARD_WIDTH_INIT,
  fetchUpdateStoryBackground,
  fetchUpdateTemplateStoryBackground,
  getInteractiveWidgetIds,
  getReducedValue,
  GroupsType,
  informSlice,
  KB_IN_MB,
  MAX_FILE_SIZE_KB,
  MAX_FILE_SIZE_MB,
  MAX_VIDEO_DURATION_SEC,
  MessageTypes,
  onUploadProgress,
  SizePresetKeys,
  Story,
  Templates,
  useGetAccessByFeature
} from '@features';
import {
  BackgroundSelector,
  SelectedWidgetMode,
  StoryEditorAction,
  useStoryEditor,
  useStoryEditorDispatch
} from '@modules';
import { RootState, useAppDispatch } from '@store';
import _ from 'lodash';
import {
  useAddStoryChangesToSave,
  useCurrentStoriesSlice,
  useCurrentStoriesType
} from '@features/stories/hooks';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppFeatures } from '@features/user/consts';
import { AppPlan } from '@features/user/types';
import { useTranslation } from 'react-i18next';
import { BackgroundColorType, BackgroundType, MediaType } from '@storysdk/react';
import { nanoid } from 'nanoid';
import { FileDrop } from 'react-file-drop';
import { useCreateMediaWidget, useGetUserSubscription } from '@hooks';
import largeIphoneMockup from '@images/iphone-15-mockup.svg';
import smallIphoneMockup from '@images/iphone-se-mockup.svg';
import { IconTemplates } from '@components';
import StoryEditorInner from '../StoryEditorInner/StoryEditorInner';
import { StoryEditorBackground, StoryEditorSafeZones, StoryEditorSelect } from './_components';
import { StorySizeToogle } from './_components/StorySizeToogle';
import './StoryEditor.scss';

export type BondsType = {
  top: boolean;
  bottom: boolean;
};
interface StoryEditorProps {
  presetWidth: number;
  presetHeight: number;
  storyPreview?: Story;
  cardTop: number;
  backgroundTop?: number;
  currentSizePreset: SizePresetKeys;
  backgroundBorderRadius: number;
  innerBorderRadius: number;
  hiddenBorderRadius: number;
  innerClassName?: string;
  isAltPressed: boolean;
  isShiftPressed: boolean;
  isHidden: boolean;
  scale: number;
  groupType: GroupsType;
  templateCategory?: Templates.Category;
  isReadOnly?: boolean;
  isNoSelectable?: boolean;
  backgroundSize: {
    width: number;
    height: number;
  };
  editorSize: {
    width: number;
    height: number;
  };
  cardSize: {
    width: number;
    height: number;
  };
  onToggleStory?: () => void;
  onChangeSizePreset(sizePreset: SizePresetKeys, disableSizeSync?: boolean): void;
}

const PREVIEW_BORDER_RADIUS = 0;

const INIT_BACKGROUND_ELEMENT_STYLES = {
  topBarHeight: 73,
  bottomBarHeight: 73
};

const b = block('StoryEditor');

export const StoryEditor: React.FC<StoryEditorProps> = ({
  cardSize,
  cardTop,
  isHidden,
  scale,
  backgroundTop,
  isAltPressed,
  innerClassName,
  currentSizePreset,
  isShiftPressed,
  groupType,
  backgroundSize,
  editorSize,
  isReadOnly,
  innerBorderRadius,
  templateCategory,
  storyPreview,
  isNoSelectable,
  presetWidth,
  presetHeight,
  backgroundBorderRadius,
  hiddenBorderRadius,
  onToggleStory,
  onChangeSizePreset
}) => {
  const storyEditorRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const { appId, groupId, templateId, type } = useParams<{
    appId?: string;
    groupId?: string;
    templateId?: string;
    type: GroupsType;
  }>();

  const { t } = useTranslation();

  const currentStoriesType = useCurrentStoriesType(type ?? GroupsType.GROUP);
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const app = useSelector((store: RootState) => store.appManager);

  const isPickerOpen = useSelector(
    (store: RootState) => store[currentStoriesType].editor.isPickerOpen
  );

  const templates = useSelector((store: RootState) => store.templates);

  const isInteractiveWidgetsAvailable = useGetAccessByFeature({
    feature: AppFeatures.INTERACTIVE_WIDGETS
  });

  const subscription = useGetUserSubscription();

  const storyEditorState = useStoryEditor();
  const story = storyEditorState?.story ?? storyPreview;
  const selectedWidgetMode = storyEditorState?.selectedWidgetMode;
  const storyEditorDispatch = useStoryEditorDispatch();
  const isOnboarding =
    groupType === GroupsType.ONBOARDING || templateCategory === Templates.Category.ONBOARDING;
  const [isLayerMoving, setIsLayerMoving] = useState(false);

  const topBarHeight = getReducedValue(
    INIT_BACKGROUND_ELEMENT_STYLES.topBarHeight * backgroundSize.width,
    CARD_WIDTH_INIT
  );

  const bottomBarHeight = getReducedValue(
    INIT_BACKGROUND_ELEMENT_STYLES.bottomBarHeight * backgroundSize.width,
    CARD_WIDTH_INIT
  );

  const handleSelectChange = (ids: string[]) => {
    storyEditorDispatch?.({
      type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
      payload: ids
    });
  };

  const [showBounds, setShowBounds] = useState<BondsType>({ top: false, bottom: false });

  const handleBoundsChange = (bounds: BondsType) => {
    if (bounds.top !== showBounds.top || bounds.bottom !== showBounds.bottom) {
      setShowBounds(bounds);
    }
  };

  const handleCreateMediaWidget = useCreateMediaWidget();

  const handleToogleSyncDesign = (isActive: boolean) => {
    if (
      storyEditorState?.story &&
      app.current &&
      app.currentLocale &&
      storyEditorState.story.storyData[app.currentLocale].is_sync_design_active !== isActive
    ) {
      storyEditorDispatch?.({
        type: StoryEditorAction.UPDATE_STORY,
        payload: {
          storyData: {
            ...storyEditorState.story.storyData,
            [app.currentLocale]: {
              ...storyEditorState.story.storyData[app.currentLocale],
              is_sync_design_active: isActive
            }
          }
        }
      });

      if (!isActive) {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.INFO,
            text: t('notification.stories.designSyncOff')
          })
        );
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.INFO,
            text: t('notification.stories.designSyncOn')
          })
        );
      }
    }
  };

  const handleChangeBackground = (background: BackgroundBlob | BackgroundType): void => {
    if (!story) return;

    if (background.type === 'video' || background.type === 'image') {
      const newModalId = nanoid(6);

      dispatch(
        informSlice.actions.addMessage({
          id: newModalId,
          type: MessageTypes.PROGRESS,
          // @ts-ignore
          text: background.value.name
        })
      );

      if (type === GroupsType.TEMPLATE && templateId) {
        dispatch(
          fetchUpdateTemplateStoryBackground({
            storyId: story.id,
            layersGroupId: story.layerData.layersGroupId,
            background: background as BackgroundBlob,
            onUploadProgress: onUploadProgress(dispatch),
            modalProgressId: newModalId
          })
        );
      } else {
        dispatch(
          fetchUpdateStoryBackground({
            storyId: story.id,
            layersGroupId: story.layerData.layersGroupId,
            background: background as BackgroundBlob,
            onUploadProgress: onUploadProgress(dispatch),
            modalProgressId: newModalId
          })
        );
      }
    } else {
      storyEditorDispatch?.({
        type: StoryEditorAction.UPDATE_STORY_BACKGROUND,
        payload: {
          background: {
            ...background,
            isFilled: story?.background.isFilled ?? false
          }
        }
      });
    }
  };

  useEffect(() => {
    storyEditorDispatch?.({
      type: StoryEditorAction.SET_STORY,
      payload: storyPreview
    });
  }, [storyPreview]);

  useAddStoryChangesToSave({
    storyPreview,
    presetWidth,
    presetHeight,
    type
  });

  if (!story) return null;

  const interactiveWidgetIds = getInteractiveWidgetIds(story.widgets);

  const currentBackgroundBorderRadius =
    type === GroupsType.GROUP && !story?.background?.isFilled ? 0 : backgroundBorderRadius;

  const handleDropFiles = (files: FileList | null) => {
    if (!files) return;

    if (files && files.length) {
      if (files[0] && files[0].size / KB_IN_MB > MAX_FILE_SIZE_KB) {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.WARN,
            text: `${t('errors.fileSize')} ${MAX_FILE_SIZE_MB}MB.`
          })
        );
      } else if (files[0].type.includes('image')) {
        handleMediaLoad(MediaType.IMAGE, files[0]);
      } else if (files[0].type.includes('video')) {
        const file = files[0];
        const fileURL = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.src = fileURL;
        video.onloadedmetadata = () => {
          const duration = video.duration;

          if (duration > MAX_VIDEO_DURATION_SEC) {
            dispatch(
              informSlice.actions.addMessage({
                type: MessageTypes.WARN,
                text: `${t('errors.videoDuration')} ${MAX_VIDEO_DURATION_SEC} sec.`
              })
            );
          } else {
            handleMediaLoad(MediaType.VIDEO, file, { duration });
          }

          video.remove();
        };
      }
    }
  };

  const handleMediaLoad = (mediaType: MediaType, file: File, metadata?: any) => {
    if (story?.background.type === BackgroundColorType.TRANSPARENT) {
      handleChangeBackground({ type: mediaType, value: file, metadata });
    } else {
      handleCreateMediaWidget(mediaType, file, metadata);
    }
  };

  return (
    <FileDrop onDrop={handleDropFiles}>
      <div
        className={b({
          selected: true
        })}
        style={{
          width: editorSize.width,
          height: editorSize.height,
          cursor: isReadOnly && !isNoSelectable ? 'pointer' : undefined
        }}
        onClick={isReadOnly && !isNoSelectable ? onToggleStory : undefined}
      >
        {!isReadOnly && isOnboarding && (
          <StorySizeToogle
            size={currentSizePreset}
            onChange={(size: SizePresetKeys) => {
              onChangeSizePreset(size, story.widgets.length === 0);
            }}
          />
        )}

        <div
          className={b('backFilling')}
          style={{
            borderRadius: hiddenBorderRadius
          }}
        />

        <StoryEditorBackground
          backgroundSize={backgroundSize}
          backgroundTop={backgroundTop}
          borderRadius={currentBackgroundBorderRadius}
          cardHeight={
            !isOnboarding ? cardSize.height - topBarHeight - bottomBarHeight : cardSize.height
          }
          cardTop={!isOnboarding ? cardTop + topBarHeight : cardTop}
          isCentered
          isFilled={story?.background?.isFilled && !isOnboarding}
          isLarge={isOnboarding}
          selectedStory={story}
        />

        <div
          className={b('protector', {
            allowEvents: story?.background.type === BackgroundColorType.TRANSPARENT && !isHidden
          })}
        >
          {story?.background.type === BackgroundColorType.TRANSPARENT && !isHidden ? (
            <div
              className={b('noBackground')}
              style={{
                transform: `translate(-50%, -50%) scale(${scale})`
              }}
            >
              <p className={b('noBackgroundTitile')}>{t('dashboard.stories.chooseBackground')}</p>
              <div className={b('noBackgroundSelect')}>
                <BackgroundSelector
                  availableTypes={[
                    BackgroundColorType.COLOR,
                    BackgroundColorType.GRADIENT,
                    MediaType.IMAGE,
                    MediaType.VIDEO
                  ]}
                  initialTab={MediaType.IMAGE}
                  isGrey
                  isTemplatesModeOn
                  value={story?.background}
                  onChange={handleChangeBackground}
                />
              </div>

              {templates.templates?.length > 0 && (
                <button
                  className={b('chooseTemplateBtn')}
                  onClick={() =>
                    dispatch(
                      currentStoriesSlice.actions.setOpenTab({
                        tab: 'templates',
                        storyId: story.id
                      })
                    )
                  }
                >
                  <IconTemplates className={b('chooseTemplateIcon').toString()} />
                  <span className={b('chooseTemplateText')}>
                    {t('dashboard.stories.chooseTemplates')}
                  </span>
                </button>
              )}
            </div>
          ) : (
            <>
              {(groupType === GroupsType.GROUP || groupType === GroupsType.ONBOARDING) &&
                !isInteractiveWidgetsAvailable &&
                interactiveWidgetIds.length > 0 &&
                subscription.plan !== AppPlan.FREE ? (
                <StoryLimitedWidgets
                  appId={appId}
                  borderRadius={hiddenBorderRadius}
                  groupId={groupId}
                  interactiveWidgetIds={interactiveWidgetIds}
                  isEditorMode
                  story={story}
                  type={type}
                />
              ) : (
                isHidden && <StoryHiddenMessage borderRadius={hiddenBorderRadius} />
              )}
            </>
          )}
        </div>

        <div
          className={innerClassName ?? ''}
          style={{
            width: cardSize.width,
            height: cardSize.height,
            borderRadius:
              isOnboarding && currentSizePreset === SizePresetKeys.IphoneSmall
                ? 0
                : innerBorderRadius,
            top: cardTop
          }}
        >
          <ErrorBoundary FallbackComponent={StoryRenderingFallback}>
            <StoryEditorInner
              bottomBarHeight={INIT_BACKGROUND_ELEMENT_STYLES.bottomBarHeight}
              editorRef={storyEditorRef}
              isAltPressed={isAltPressed}
              isHidden={isHidden}
              isLayerMoving={isLayerMoving}
              isShiftPressed={isShiftPressed}
              presetHeight={presetHeight}
              presetWidth={presetWidth}
              scale={scale}
              story={story}
              topBarHeight={INIT_BACKGROUND_ELEMENT_STYLES.topBarHeight}
              onBoundsChange={!isOnboarding ? handleBoundsChange : undefined}
              onLayerMoving={setIsLayerMoving}
              onToogleSyncDesign={handleToogleSyncDesign}
            />
          </ErrorBoundary>
        </div>

        <StoryEditorSafeZones
          backgroundSize={backgroundSize}
          backgroundTop={!isOnboarding ? cardTop : backgroundTop}
          borderRadius={isReadOnly ? PREVIEW_BORDER_RADIUS : currentBackgroundBorderRadius}
          bottomBarHeight={bottomBarHeight}
          cardHeight={cardSize.height}
          cardTop={cardTop}
          isCentered
          isFilled={story?.background?.isFilled && !isOnboarding}
          isOnboarding={isOnboarding}
          isSafeZones={!isOnboarding && !isReadOnly}
          showBounds={showBounds}
          showTopbar={currentSizePreset === SizePresetKeys.IphoneLarge}
          topBarHeight={topBarHeight}
        />

        <img
          alt=""
          className={b('mockup')}
          src={
            currentSizePreset === SizePresetKeys.IphoneLarge ? largeIphoneMockup : smallIphoneMockup
          }
        />
      </div>

      {!isAltPressed &&
        selectedWidgetMode !== SelectedWidgetMode.EDIT &&
        !isHidden &&
        !(
          (groupType === GroupsType.GROUP || groupType === GroupsType.ONBOARDING) &&
          !isInteractiveWidgetsAvailable &&
          interactiveWidgetIds.length > 0 &&
          subscription.plan !== AppPlan.FREE
        ) &&
        !isLayerMoving &&
        !isReadOnly &&
        !isPickerOpen &&
        storyEditorRef.current &&
        story?.background.type !== BackgroundColorType.TRANSPARENT && (
          <StoryEditorSelect
            container={storyEditorRef.current}
            scale={scale}
            selectable=".StoryObjectComponent"
            selectedStory={story}
            onChange={handleSelectChange}
          />
        )}
    </FileDrop>
  );
};
