import { useEffect } from 'react';
import { useAppDispatch } from '@store';
import { GroupsType } from '@features/groups';
import { useDebounce } from '@hooks';
import { ChangedObjectsType, SaveStatus, StoryLayers } from '../types';
import { fetchSaveStory } from '../storiesSlice';
import { useCurrentStoriesSlice } from './useCurrentStoriesSlice';

export const useSaveChanges = ({
  changedObjects,
  stories,
  appId,
  groupId,
  templateId
}: {
  changedObjects: ChangedObjectsType[];
  stories: StoryLayers;
  appId?: string;
  groupId?: string;
  templateId?: string;
}) => {
  const changedObjectsDebounced = useDebounce(changedObjects, 100);
  const dispatch = useAppDispatch();
  const currentStoriesSlice = useCurrentStoriesSlice(
    templateId ? GroupsType.TEMPLATE : GroupsType.GROUP
  );

  useEffect(() => {
    const notSavedStoryObjects = changedObjectsDebounced
      .filter((item) => item.saveStatus === SaveStatus.NOT_SAVED)
      .reduce(
        (
          acc: {
            [storyId: string]: {
              widgetId: string | null;
              layersGroupId: string;
              syncToLocales?: boolean;
            };
          },
          item
        ) => {
          acc[item.storyId] = {
            widgetId: item.widgetId ?? null,
            layersGroupId: item.layersGroupId,
            syncToLocales: item.syncToLocales
          };

          return acc;
        },
        {}
      );

    if (Object.keys(notSavedStoryObjects).length === 0) {
      dispatch(currentStoriesSlice.actions.setSavingStatus('saved'));

      const savedStoryObjects = changedObjectsDebounced.filter(
        (item) => item.saveStatus === SaveStatus.SAVED
      );

      savedStoryObjects.forEach((item) => {
        dispatch(currentStoriesSlice.actions.deleteChangedObject(item.id));
      });

      return;
    }

    dispatch(currentStoriesSlice.actions.setSavingStatus('saving'));

    for (const storyId in notSavedStoryObjects) {
      if (Object.prototype.hasOwnProperty.call(notSavedStoryObjects, storyId)) {
        const story = stories?.[notSavedStoryObjects[storyId].layersGroupId]?.layers?.[storyId];

        // if (notSavedStoryObjects[storyId].widgetId) {
        //   const widget = stories?.[notSavedStoryObjects[storyId].layersGroupId]?.layers[
        //     storyId
        //   ].widgets.find((w) => w.id === notSavedStoryObjects[storyId].widgetId);

        //   if (widget) {
        //     let sizes: WidgetElemetsSizes | undefined;
        //     if (WidgetsToImage.includes(widget.content.type)) {
        //       const widgetElement = document.querySelector(`#${widget.id}`);

        //       if (widgetElement) {
        //         const title = widgetElement.querySelector('.StorySdk-widgetTitle') as HTMLElement;
        //         const answers = widgetElement.querySelectorAll(
        //           '.StorySdk-widgetAnswerTitle'
        //         ) as NodeListOf<HTMLElement>;

        //         sizes = {
        //           widget: {
        //             width: getValue(widgetElement.clientWidth),
        //             height: getValue(widgetElement.clientHeight)
        //           },
        //           title: {
        //             width: title ? getValue(title.clientWidth) : 0,
        //             height: title ? getValue(title.clientHeight) : 0,
        //             fontSize: title ? getValue(parseInt(title.style.fontSize, 10)) : 0
        //           }
        //         };

        //         answers.forEach((answer) => {
        //           const answerId = answer.dataset.id;

        //           if (!answerId || !sizes) return;

        //           sizes.answers = sizes.answers || {};

        //           sizes.answers[answerId] = {
        //             width: getValue(answer.clientWidth),
        //             height: getValue(answer.clientHeight),
        //             fontSize: getValue(parseInt(answer.style.fontSize, 10))
        //           };
        //         });
        //       }
        //     }

        //     dispatch(
        //       fetchSaveWidgetImage({
        //         appId,
        //         groupId,
        //         templateId,
        //         story,
        //         widget,
        //         sizes,
        //         storyWidth,
        //         storyHeight
        //       })
        //     );
        //   }
        // } else {
        dispatch(
          fetchSaveStory({
            appId,
            groupId,
            templateId,
            story
          })
        );
      }
      // }
    }
  }, [changedObjectsDebounced]);
};
