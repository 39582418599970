import React from 'react';
import block from 'bem-cn';
import './StoryVideoBackground.scss';

const b = block('StoryVideoBackground');

type PropTypes = {
  src: string;
  isLoading?: boolean;
  isFilled?: boolean;
  autoplay?: boolean;
  borderRadius?: number;
  onLoadStart?: () => void;
  onLoadEnd?: () => void;
};

export const StoryVideoBackground = ({
  src,
  autoplay = false,
  isLoading,
  isFilled,
  borderRadius,
  onLoadStart,
  onLoadEnd
}: PropTypes) => (
  <div
    className={b()}
    style={{
      borderRadius
    }}
  >
    <video
      autoPlay={autoplay}
      className={b('video', { loading: isLoading, cover: isFilled })}
      loop
      muted
      playsInline
      preload="metadata"
      src={src}
      onLoadStart={onLoadStart}
      onLoadedData={onLoadEnd}
    />

    <p className={b('loadText', { show: isLoading })}>Background is loading...</p>
  </div>
);
