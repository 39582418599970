import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConfirmEmailModal } from '@modules/AuthModule/_components';
import { AddAppPage, DowngradeModal, PlansModal } from '@modules';
import {
  useFetchApps,
  useSelectApp,
  ChooseAppContainer,
  informSlice,
  MessageTypes,
  groupsSlice,
  EditGroupModal
} from '@features';
import { useTranslation } from 'react-i18next';
import { useSetBodyOverflow } from '@features/stories/hooks';
import { productsSlice } from '@features/products/productsSlice';
import { Header } from '@components';
import { useAuth, useGetUserSubscription } from '@hooks';
import { AppSideNav } from '../AppSideNav/AppSideNav';
import { RootState, useAppDispatch } from '../../../../store';
import './DashboardLayout.scss';

const b = block('DashboardLayout');

interface DashboardLayoutProps {
  children?: React.ReactNode;
  isNoRedirect?: boolean;
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { children, isNoRedirect } = props;
  const { appId } = useParams<{ appId: string }>();
  const userState = useSelector((store: RootState) => store.user);
  const app = useSelector((store: RootState) => store.appManager);
  const plansModalType = useSelector((store: RootState) => store.products.plansModalType);
  const downgradablePlan = useSelector((store: RootState) => store.products.downgradablePlan);
  const [isShowedNotification, setIsShowedNotification] = useState(false);
  const dispatch = useAppDispatch();
  const [isConfirmOpen, setIsConfrimOpen] = useState(false);

  const { t } = useTranslation();

  const editGroup = useSelector((store: RootState) => store.groups.editGroup);

  useEffect(() => {
    if (
      userState.status === 'loaded' &&
      userState.user.email &&
      !userState.user.isEmailConfirmed &&
      !(userSubcription.trialLeft <= 0 && !isShowedNotification)
    ) {
      setIsConfrimOpen(true);
    }
  }, [userState]);

  useAuth();
  useFetchApps(!appId && !isNoRedirect);
  useSelectApp(appId);
  useSetBodyOverflow(!!plansModalType);

  const userSubcription = useGetUserSubscription();

  useEffect(() => {
    if (!userSubcription.isLoaded) return;

    if (userSubcription.isUnpaid && !isShowedNotification) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.NEED_ACTION,
          text: t('notification.subscription.billingIssue'),
          action: {
            text: t('user.updatePaymentMethod'),
            link: `${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${userState.user.email}`,
            isExternal: true
          }
        })
      );

      setIsShowedNotification(true);
    } else if (
      !userSubcription.isSubscribed &&
      userSubcription.trialLeft <= 0 &&
      !isShowedNotification
    ) {
      dispatch(productsSlice.actions.setPlansModalType('create'));
    }
  }, [userSubcription]);

  return (
    <div className={b()}>
      <Header
        className={`${b('header')}`}
        leftNav={app.current ? <ChooseAppContainer /> : undefined}
        withLogo={false}
      />

      <AppSideNav className={`${b('side-nav')}`} isLoading={!app.current} />

      <section className={b('content')}>
        {app.appsList.length === 0 && !app.current && app.status === 'loaded' ? (
          <AddAppPage />
        ) : (
          children
        )}
      </section>
      <ConfirmEmailModal isOpen={isConfirmOpen} onClose={() => setIsConfrimOpen(false)} />
      <PlansModal
        isOpen={!!plansModalType}
        onClose={() => {
          dispatch(productsSlice.actions.setPlansModalType(undefined));
        }}
      />
      <DowngradeModal
        isOpen={!!downgradablePlan}
        plan={downgradablePlan}
        onClose={() => dispatch(productsSlice.actions.setDowngradablePlan(undefined))}
      />
      <EditGroupModal
        group={editGroup}
        isOpen={!!editGroup}
        onClose={() => dispatch(groupsSlice.actions.setEditGroup(undefined))}
      />
    </div>
  );
};
