import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '@store';
import {
  AppCollaborationFeatures,
  GroupsType,
  Templates,
  fetchCreateTemplateStory,
  useGetActionByAccess,
  useGetActionByCollaboratorRole
} from '@features';
import { useTranslation } from 'react-i18next';
import { StoryEditorAction, useStoryEditorDispatch } from '@modules';
import { useCurrentStoriesType } from '@features/stories/hooks';
import { AppFeatures } from '@features/user/consts';
import { Icon, IconAddPlus } from '@components';
import { getInitStoryParams } from '@utils';
import { useIsMobile } from '@hooks';
import { fetchCreateStory } from '../../storiesSlice';
import './AddStory.scss';

const b = block('AddStory');

interface AddStoryProps {
  type: GroupsType;
  isEditorMode?: boolean;
  view?: 'card' | 'button';
}

const AddStory: React.FC<AddStoryProps> = ({ isEditorMode, type, view = 'card' }) => {
  const history = useHistory();
  const currentStoriesType = useCurrentStoriesType(type);
  const stories = useSelector((store: RootState) => store[currentStoriesType]);

  const isMobile = useIsMobile();

  const { appId, groupId, templateId, category } = useParams<{
    appId: string;
    groupId: string;
    templateId?: string;
    category?: Templates.Category;
  }>();
  const dispatch = useAppDispatch();
  const storyEditorDispatch = useStoryEditorDispatch();

  const { t } = useTranslation();

  let lastLayersGroupPosition = 0;

  Object.values(stories.stories).forEach((story) => {
    if (story.position > lastLayersGroupPosition) {
      lastLayersGroupPosition = story.position;
    }
  });

  const onActionClick = useGetActionByAccess();
  const onActionClickCollaborator = useGetActionByCollaboratorRole();

  const handleCreateStory = () => {
    storyEditorDispatch?.({
      type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
      payload: []
    });

    if (templateId && type === GroupsType.TEMPLATE) {
      dispatch(
        fetchCreateTemplateStory({
          templateId,
          story: getInitStoryParams({
            position: lastLayersGroupPosition + 1
          }),
          isEditor: isEditorMode
        })
      );

      if (!isEditorMode) {
        history.push({
          pathname: `/editor/template/${templateId}/${category}`
        });
      }
    } else {
      dispatch(
        fetchCreateStory({
          appId,
          groupId,
          type,
          story: getInitStoryParams({
            position: type === GroupsType.GROUP ? 1 : lastLayersGroupPosition + 1
          }),
          isEditor: isEditorMode
        })
      ).then((result: any) => {
        if (!result.payload || isMobile) return;

        history.push({
          pathname: `/editor/${appId}/${type}/${groupId}`,
          state: {
            storyId: result.payload.id,
            layersGroupId: result.payload.layerData.layersGroupId
          }
        });
      });
    }
  };

  return (
    <>
      {view === 'card' ? (
        <button
          className={b({ editorVariant: isEditorMode })}
          onClick={() =>
            onActionClickCollaborator(
              type === GroupsType.ONBOARDING
                ? AppCollaborationFeatures.CREATE_ONBOARDING
                : AppCollaborationFeatures.CREATE_STORIES,
              () => {
                onActionClick(
                  type === GroupsType.ONBOARDING
                    ? AppFeatures.CREATE_ONBOARDING
                    : AppFeatures.CREATE_STORIES,
                  handleCreateStory
                );
              }
            )
          }
        >
          <div className={b('circle')}>
            {isEditorMode ? (
              <Icon className={b('plus-icon').toString()} name="add" variant="editor" />
            ) : (
              <Icon className={b('plus-icon').toString()} name="add-large" variant="editor" />
            )}
          </div>

          <p className={b('text')}>{t('dashboard.stories.add')}</p>
        </button>
      ) : (
        <button
          className={b('addBtn')}
          onClick={() =>
            onActionClickCollaborator(
              type === GroupsType.ONBOARDING
                ? AppCollaborationFeatures.CREATE_ONBOARDING
                : AppCollaborationFeatures.CREATE_STORIES,
              () => {
                onActionClick(
                  type === GroupsType.ONBOARDING
                    ? AppFeatures.CREATE_ONBOARDING
                    : AppFeatures.CREATE_STORIES,
                  handleCreateStory
                );
              }
            )
          }
        >
          <IconAddPlus className={b('addBtnIcon').toString()} />
        </button>
      )}
    </>
  );
};

export default AddStory;
