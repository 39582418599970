import React from 'react';
import { SettingsBox, StoryEditorAction, useStoryEditor, useStoryEditorDispatch } from '@modules';
import { useTranslation } from 'react-i18next';
import {
  ControlGroup,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsInput
} from '@features/stories/editorSettings/components';
import {
  STORY_HEIGHT,
  STORY_WIDTH,
  SizePreset,
  getAlignHorizontalWidgets,
  getAlignVerticalWidgets
} from '@features';

interface StoryGroupToolsProps {
  currentSizePreset: SizePreset;
}

export const StoryGroupTools: React.FC<StoryGroupToolsProps> = ({ currentSizePreset }) => {
  const storyEditorState = useStoryEditor();
  const storyEditorDispatch = useStoryEditorDispatch();
  const selectedStory = storyEditorState?.story;
  const selectedWidgetIds = storyEditorState?.selectedWidgetsIds ?? [];
  const groupPosition = storyEditorState?.group;

  const { t } = useTranslation();

  const handleAlignChange = (params: SettingAlignOnChangeType) => {
    const widgets = selectedStory?.widgets.filter((item) => selectedWidgetIds.includes(item.id));

    if (params.horizontal && groupPosition && widgets) {
      const newWidgets = getAlignHorizontalWidgets({
        align: params.horizontal,
        container: groupPosition,
        widgets,
        storyWidth: currentSizePreset.width,
        storyHeight: currentSizePreset.height
      });

      newWidgets.forEach((widget) => {
        storyEditorDispatch?.({
          type: StoryEditorAction.UPDATE_STORY_WIDGET,
          payload: {
            widget,
            storyWidth: currentSizePreset.width,
            storyHeight: currentSizePreset.height
          }
        });
      });

      storyEditorDispatch?.({
        type: StoryEditorAction.SET_GROUP_ALIGN,
        payload: params.horizontal
      });
    } else if (params.vertical && groupPosition && widgets) {
      const newWidgets = getAlignVerticalWidgets({
        align: params.vertical,
        container: groupPosition,
        widgets,
        storyWidth: currentSizePreset.width,
        storyHeight: currentSizePreset.height
      });

      newWidgets.forEach((widget) => {
        storyEditorDispatch?.({
          type: StoryEditorAction.UPDATE_STORY_WIDGET,
          payload: { widget }
        });
      });
      storyEditorDispatch?.({
        type: StoryEditorAction.SET_GROUP_ALIGN,
        payload: params.vertical
      });
    }
  };

  return (
    <SettingsBox>
      <SettingsBox.Title>{t('editor.groupSettings')}</SettingsBox.Title>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={handleAlignChange} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      {groupPosition && (
        <SettingsBox.Group title={t('editor.position')}>
          <SettingsBox.Field>
            <ControlGroup isLocked>
              <SettingsInput
                disabled
                postfix="px"
                prefix="X"
                type="number"
                value={groupPosition.x}
              />
              <SettingsInput
                disabled
                postfix="px"
                prefix="Y"
                type="number"
                value={groupPosition.y}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup isLocked>
              <SettingsInput
                disabled
                postfix="px"
                prefix="W"
                type="number"
                value={groupPosition.width}
              />
              <SettingsInput
                disabled
                postfix="px"
                prefix="H"
                type="number"
                value={groupPosition.height}
              />
            </ControlGroup>
          </SettingsBox.Field>
        </SettingsBox.Group>
      )}
    </SettingsBox>
  );
};
