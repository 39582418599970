import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteGroupModal } from '@features/groups/components/DeleteGroupModal/DeleteGroupModal';
import {
  CropProps,
  fetchUpdateGroup,
  fetchDeleteGroup,
  KB_IN_MB,
  informSlice,
  MAX_GROUP_AVATAR_SIZE_KB,
  MAX_GROUP_AVATAR_SIZE_MB,
  GroupsType,
  MessageTypes,
  useGetAccessByCollaboratorRole,
  AppCollaborationFeatures,
  useGetActionByCollaboratorRole
} from '@features';
import { DateTime } from 'luxon';
import { RootState, useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import { useGroup } from '@features/groups/hooks';
import QRCode from 'react-qr-code';
import {
  FreshButton,
  FreshDropArea,
  FreshInput,
  FreshLinkOutput,
  FreshSwitchControl
} from '@components/_fresh';
import { IconSaveFresh } from '@components/Icon/icons';
import { useShareLink } from '@hooks';
import './GroupSettingsTab.scss';

interface FormProps {
  name: string | null;
  startDate: number | null;
  endDate: number | null;
  isActive: boolean;
  isAutoplayVideos: boolean;
  image: string | null;
}

const b = block('GroupSettingsTab');

export const GroupSettingsTab: React.FC = () => {
  const initialCroppedState = {
    dataUrl: null,
    blob: null
  };

  const initialFormData = {
    name: null,
    startDate: null,
    endDate: null,
    isActive: false,
    isAutoplayVideos: false,
    image: null
  };

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [croppedImage, setCroppedImage] = useState<CropProps>(initialCroppedState);
  const [formData, setFormData] = useState<FormProps>(initialFormData);
  const imageFiles = React.useRef<File[] | null>(null);
  const appState = useSelector((state: RootState) => state);
  const { groupId } = useParams<{ groupId: string }>();
  const storiesGroup = useGroup(groupId, GroupsType.GROUP);
  const app = appState.appManager;
  const { t } = useTranslation();

  const isDeleteAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.ARCHIVE_APP
  });

  const onActionClickCollaborator = useGetActionByCollaboratorRole();

  const shareLink = useShareLink({
    groupId: storiesGroup?.id ?? '',
    type: GroupsType.GROUP
  });

  useEffect(() => {
    if (storiesGroup) {
      setCroppedImage({
        dataUrl: null,
        blob: null
      });
      setFormData({
        image: storiesGroup.image,
        name: storiesGroup.title,
        startDate: +storiesGroup.startDate || DateTime.now().toMillis(),
        endDate: +storiesGroup.endDate || null,
        isActive: storiesGroup.active,
        isAutoplayVideos: storiesGroup.settings?.autoplayVideos ?? false
      });
    }
  }, [storiesGroup]);

  if (!storiesGroup) {
    return null;
  }

  const handleChangeName = ({ value }: { value: string }) => {
    setFormData((prevState) => ({ ...prevState, name: value }));
  };

  const handleFileChange = (files: File[]) => {
    if (files[0] && files[0].size / KB_IN_MB > MAX_GROUP_AVATAR_SIZE_KB) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.WARN,
          text: `${t('errors.fileSize')} ${MAX_GROUP_AVATAR_SIZE_MB}MB.`
        })
      );
    } else {
      imageFiles.current = files;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = () => {
        setCroppedImage({
          dataUrl: reader.result as string,
          blob: file
        });
      };
    }
  };

  const handleActiveToggle = () => {
    setFormData((prevState) => ({ ...prevState, isActive: !prevState.isActive }));
  };

  const handleAutoplaVideosToggle = () => {
    setFormData((prevState) => ({ ...prevState, isAutoplayVideos: !prevState.isAutoplayVideos }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (app.current && storiesGroup) {
      dispatch(
        fetchUpdateGroup({
          appId: app.current.id,
          groupId: storiesGroup.id,
          image: croppedImage.blob ?? null,
          active: formData.isActive,
          title: formData.name ?? '',
          startTime: formData.startDate,
          endTime: formData.endDate,
          isAutoplayVideos: formData.isAutoplayVideos
        })
      );
    }
  };

  const handleDeleteGroup = () => {
    if (app.current && storiesGroup) {
      dispatch(
        fetchDeleteGroup({
          appId: app.current.id,
          groupId: storiesGroup.id
        })
      ).then((res) => {
        setDeleteOpen(false);

        if (app.current) {
          history.push(`/dashboard/${app.current.id}/stories`);
        }
      });
    }
  };

  return (
    <>
      <form className={b()} onSubmit={handleSubmit}>
        <div className={b('formHeader')}>
          <h1 className={b('formHeaderTitle')}>{formData.name}</h1>

          <FreshButton
            disabled={!formData.name}
            leftIcon={<IconSaveFresh />}
            size="md"
            text={t('form.saveSettings')}
            type="submit"
          />
        </div>

        <div className={b('formColumn')}>
          <div className={b('filedSet', { col: 2 })}>
            <FreshDropArea
              className={`${b('dropArea')}`}
              currentImage={croppedImage.dataUrl || formData.image}
              handleFileChange={handleFileChange}
            />
            <div className={b('fieldGroup')}>
              <p className={b('fieldLabel')}>{t('dashboard.groups.groupName')}</p>

              <FreshInput
                fieldName="name"
                invalid={!formData.name}
                placeholder={t(`dashboard.groups.groupName`)}
                type="text"
                value={formData.name ?? undefined}
                onChange={handleChangeName}
              />
            </div>
          </div>

          <div className={b('filedSet', { flex: 'column' })}>
            <p className={b('fieldsetLabel')}>{t('dashboard.groups.shareLink')}</p>

            <FreshLinkOutput className={b('field').toString()} value={shareLink} />

            <p className={b('mainDesc')}>{t('dashboard.groups.qrDesc')}</p>

            <QRCode size={172} value={shareLink} />
          </div>

          <div className={b('filedSet', { flex: 'between' })}>
            <p className={b('fieldLabel', { noMargin: true })}>
              {t('dashboard.groups.publishedGroup')}
            </p>

            <FreshSwitchControl
              className={b('switch')}
              isChecked={formData.isActive}
              onToggle={() =>
                onActionClickCollaborator(
                  AppCollaborationFeatures.PUBLISH_STORIES,
                  handleActiveToggle
                )
              }
            />
          </div>

          <div className={b('filedSet', { flex: 'between' })}>
            <p className={b('fieldLabel', { noMargin: true })}>
              {t('dashboard.groups.autoplayVideos')}
            </p>

            <FreshSwitchControl
              className={b('switch')}
              isChecked={formData.isAutoplayVideos}
              onToggle={handleAutoplaVideosToggle}
            />
          </div>

          {isDeleteAvailable && (
            <a
              className={b('link')}
              role="button"
              tabIndex={0}
              onClick={() => setDeleteOpen(true)}
              onKeyDown={() => setDeleteOpen(true)}
            >
              {t('dashboard.groups.deleteGroup')}
            </a>
          )}
        </div>
      </form>

      <DeleteGroupModal
        handleDeleteGroup={handleDeleteGroup}
        image={(croppedImage.dataUrl as string) || (formData.image as string)}
        isOpen={isDeleteOpen}
        title={formData.name as string}
        onClose={() => setDeleteOpen(false)}
      />
    </>
  );
};
