import { useEffect } from 'react';
import { useStoryEditor } from '@modules';
import { RootState, useAppDispatch } from '@store';
import { GroupsType } from '@features/groups';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useDebounce } from '@hooks';
import { Story } from '../types';
import { useCurrentStoriesSlice } from '.';

export const useAddStoryChangesToSave = ({
  storyPreview,
  presetWidth,
  presetHeight,
  type
}: {
  storyPreview?: Story;
  presetWidth: number;
  presetHeight: number;
  type: GroupsType;
}) => {
  const dispatch = useAppDispatch();

  const storyEditorState = useStoryEditor();
  const storyStateChanged = useDebounce(storyEditorState?.story, 100);
  const currentStoriesSlice = useCurrentStoriesSlice(type);
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);

  useEffect(() => {
    if (!storyStateChanged || storyStateChanged.id !== storyPreview?.id) {
      return;
    }

    if (storyStateChanged.saveOnlyBackground) {
      delete storyStateChanged.saveOnlyBackground;

      dispatch(
        currentStoriesSlice.actions.setBackground({
          background: storyStateChanged.background,
          currentLocale
        })
      );
    } else if (!storyStateChanged.saveAll) {
      storyStateChanged.widgets.forEach((widget) => {
        const prevWidget = storyPreview?.widgets.find((w) => w.id === widget.id);
        const widgetHTML = document.getElementById(widget.id);
        const realWidth = widgetHTML?.offsetWidth;
        const realHeight = widgetHTML?.offsetHeight;

        if (prevWidget && !_.isEqual(widget, prevWidget)) {
          let updatedWidget = { ...widget };
          let stopSaveImage = false;
          let syncToLocales = false;

          if (realWidth && realHeight) {
            updatedWidget = {
              ...updatedWidget,
              positionByResolutions: {
                ...updatedWidget.positionByResolutions,
                [`${presetWidth}x${presetHeight}`]: {
                  ...updatedWidget.positionByResolutions[`${presetWidth}x${presetHeight}`],
                  realWidth,
                  realHeight
                }
              }
            };
          }

          // @ts-ignore
          if (updatedWidget.stopSaveImage) {
            stopSaveImage = true;
            // @ts-ignore
            delete updatedWidget.stopSaveImage;
          }

          // @ts-ignore
          if (updatedWidget.syncToLocales) {
            syncToLocales = true;
            // @ts-ignore
            delete updatedWidget.syncToLocales;
          }

          dispatch(
            currentStoriesSlice.actions.updateStoryWidget({
              widgetId: widget.id,
              widget: updatedWidget,
              currentLocale,
              syncToLocales,
              isHasUnpublishedChanges: storyStateChanged.isHasUnpublishedChanges
            })
          );
        } else if (!prevWidget) {
          let addedWidget = { ...widget };

          if (realWidth && realHeight) {
            addedWidget = {
              ...addedWidget,
              positionByResolutions: {
                ...addedWidget.positionByResolutions,
                [`${presetWidth}x${presetHeight}`]: {
                  ...addedWidget.positionByResolutions[`${presetWidth}x${presetHeight}`],
                  realWidth,
                  realHeight
                }
              }
            };
          }
          dispatch(
            currentStoriesSlice.actions.addStoryWidget({ widget: addedWidget, currentLocale })
          );
        }
      });
    } else if (storyStateChanged.saveAll) {
      delete storyStateChanged.saveAll;

      dispatch(currentStoriesSlice.actions.updateStory(storyStateChanged));
    }
  }, [storyStateChanged]);
};
