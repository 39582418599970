import React from 'react';
import {
  WidgetsTypes,
  WidgetPositionType,
  WidgetPositionLimitsType,
  ClickMeWidget,
  EllipseWidget,
  EmojiReactionWidget,
  QuestionWidget,
  RectangleWidget,
  SliderWidget,
  SwipeUpWidget,
  TalkAboutWidget,
  TimerWidget,
  ChooseAnswerWidget,
  QuizOneAnswerWidget,
  QuizMultipleAnswerWidget,
  QuizMultipleAnswerWithImageWidget,
  QuizOpenAnswerWidget,
  QuizRateWidget,
  GiphyWidget,
  ImageWidget,
  VideoWidget,
  WidgetObjectType,
  LinkWidget
} from '@storysdk/react';
import { TextWidgetInput } from '@modules';

export const renderWidgetControl = ({
  content,
  position,
  positionLimits,
  elementsSize,
  isEditWidgetMode,
  handleChangheWidgetContent
}: {
  content: WidgetObjectType['content'];
  position?: WidgetPositionType;
  positionLimits?: WidgetPositionLimitsType;
  elementsSize?: WidgetPositionType['elementsSize'];
  isEditWidgetMode?: boolean;
  handleChangheWidgetContent?: (payload: WidgetObjectType['content']['params']) => void;
}) => {
  switch (content.type) {
    case WidgetsTypes.RECTANGLE:
      return <RectangleWidget params={content.params} />;
    case WidgetsTypes.ELLIPSE:
      return <EllipseWidget params={content.params} />;
    case WidgetsTypes.TEXT:
      return (
        <TextWidgetInput
          handleChangheWidgetContent={handleChangheWidgetContent}
          isEditWidgetMode={isEditWidgetMode}
          params={content.params}
        />
      );
    case WidgetsTypes.SWIPE_UP:
      return <SwipeUpWidget isReadOnly params={content.params} />;
    case WidgetsTypes.SLIDER:
      return <SliderWidget elementsSize={elementsSize} isReadOnly params={content.params} />;
    case WidgetsTypes.QUESTION:
      return <QuestionWidget elementsSize={elementsSize} isReadOnly params={content.params} />;
    case WidgetsTypes.IMAGE:
      return <ImageWidget params={content.params} />;
    case WidgetsTypes.VIDEO:
      return <VideoWidget autoplay isDisplaying params={content.params} />;
    case WidgetsTypes.CLICK_ME:
      return <ClickMeWidget isReadOnly params={content.params} />;
    case WidgetsTypes.LINK:
      return <LinkWidget isReadOnly params={content.params} />;
    case WidgetsTypes.TALK_ABOUT:
      return <TalkAboutWidget elementsSize={elementsSize} isReadOnly params={content.params} />;
    case WidgetsTypes.EMOJI_REACTION:
      return <EmojiReactionWidget elementsSize={elementsSize} isReadOnly params={content.params} />;
    case WidgetsTypes.TIMER:
      return (
        <TimerWidget params={content.params} position={position} positionLimits={positionLimits} />
      );
    case WidgetsTypes.CHOOSE_ANSWER:
      return <ChooseAnswerWidget elementsSize={elementsSize} isReadOnly params={content.params} />;
    case WidgetsTypes.GIPHY:
      return <GiphyWidget params={content.params} />;
    case WidgetsTypes.QUIZ_ONE_ANSWER:
      return <QuizOneAnswerWidget elementsSize={elementsSize} isReadOnly params={content.params} />;
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS:
      return (
        <QuizMultipleAnswerWidget elementsSize={elementsSize} isReadOnly params={content.params} />
      );
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE:
      return (
        <QuizMultipleAnswerWithImageWidget
          elementsSize={elementsSize}
          isReadOnly
          params={content.params}
        />
      );
    case WidgetsTypes.QUIZ_OPEN_ANSWER:
      return (
        <QuizOpenAnswerWidget elementsSize={elementsSize} isReadOnly params={content.params} />
      );
    case WidgetsTypes.QUIZ_RATE:
      return <QuizRateWidget elementsSize={elementsSize} isReadOnly params={content.params} />;
    default:
      throw new Error('Unknown widget');
  }
};
