import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { fetchCancelSubscription, groupsSlice, informSlice, MessageTypes } from '@features';
import { AppPlan, UserSubscriptionStatus } from '@features/user/types';
import { RootState } from '@store';
import i18n from '../../i18n/i18n';
import { App, FormState, AppsState, LocaleType, AppSettings, SavingStatus, AppBase } from './types';

const initialState: AppsState = {
  appsList: [],
  status: 'idle',
  isFirstUsing: false,
  savingStatus: 'saved',
  current: null,
  currentLocale: null,
  locales: []
};

export const appsManagerSlice = createSlice({
  name: 'appsManager',
  initialState,
  reducers: {
    reset: () => initialState,
    initApps: (state, action: PayloadAction<AppsState['appsList']>) => {
      state.appsList = action.payload;
      state.status = 'loaded';
    },
    initLocales: (state, action: PayloadAction<AppsState['locales']>) => {
      state.locales = action.payload;
    },
    addAppLocalization: (state, action: PayloadAction<LocaleType>) => {
      if (!state.current) {
        return;
      }

      if (
        !state.current.appLocalization.languages.find(
          (item: LocaleType) => item.shortName === action.payload.shortName
        )
      ) {
        state.current.appLocalization.languages = [
          ...state.current.appLocalization.languages,
          action.payload
        ];
      }
    },
    removeAppLocalization: (state, action: PayloadAction<string>) => {
      if (!state.current) {
        return;
      }

      const newLocales = state.current.appLocalization.languages.filter(
        (item: any) => item.shortName !== action.payload
      );

      if (action.payload === state.current.appLocalization.default) {
        state.current.appLocalization.default = newLocales[0].shortName;
      }

      state.current.appLocalization.languages = newLocales;
    },
    setDefaultLanguage: (state, action: PayloadAction<string>) => {
      if (!state.current) {
        return;
      }

      state.current.appLocalization.default = action.payload;
      state.currentLocale = action.payload;
    },
    setIsFirstUsing: (state, action: PayloadAction<boolean>) => {
      state.isFirstUsing = action.payload;
    },
    setCurrentLocale: (state, action: PayloadAction<string>) => {
      state.currentLocale = action.payload;
    },
    setSettings: (state, action: PayloadAction<AppSettings>) => {
      if (state.current) {
        state.current.settings = action.payload;
      }
    },
    setTitle: (state, action: PayloadAction<string>) => {
      if (state.current) {
        state.current.title = action.payload;
      }
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setSavingStatus: (state, action: PayloadAction<SavingStatus>) => {
      state.savingStatus = action.payload;
    },
    selectApp: (state, action: PayloadAction<App>) => {
      state.current = action.payload;

      if (action.payload.appLocalization && !state.currentLocale) {
        state.currentLocale = action.payload.appLocalization.default;
      }
    },
    createApp: (state, action: PayloadAction<App>) => {
      state.appsList = state.appsList.concat([
        {
          id: action.payload.id,
          title: action.payload.title,
          ownerId: action.payload.ownerId
        }
      ]);
    },
    updateApp: (state, action: PayloadAction<App>) => {
      state.current =
        state.current?.id === action.payload.id
          ? { ...state.current, ...action.payload }
          : state.current;

      state.appsList = state.appsList.map((app) =>
        app.id === action.payload.id ? { ...app, ...action.payload } : app
      );

      state.savingStatus = 'saved';
    },
    updateStoriesCount: (state, action: PayloadAction<number>) => {
      if (state.current) {
        state.current.storiesCount = action.payload;
      }
    },
    updateCollaboratorsCount: (state, action: PayloadAction<number>) => {
      if (state.current) {
        state.current.collaboratorsCount = action.payload;
      }
    },
    updateSubscriptionStatus: (state, action: PayloadAction<UserSubscriptionStatus>) => {
      if (state.current) {
        state.current.subscriptionStatus = action.payload;
      }
    },
    removeApp: (state, action: PayloadAction<string>) => {
      const findIndex = state.appsList.findIndex(({ id }) => id === action.payload);
      state.appsList.splice(findIndex, 1);
    }
  }
});

export const fetchCreateApp = createAsyncThunk(
  'appsManager/fetchCreateApp',
  async (form: FormState, { dispatch, getState }) => {
    const { data } = await API.apps.create(form);
    const state = getState() as RootState;

    const appLocalization = {
      ...form.localization,
      languages: form.localization.languages.map((langItem: string) =>
        // @ts-ignore
        state.appManager.locales.find((locale: any) => locale.shortName === langItem)
      )
    };

    if (data && !data.error) {
      const { data: appData } = await API.apps.get({ appId: data.data.id });

      if (appData && !appData.error) {
        const payload = {
          id: appData.data.id,
          title: appData.data.title,
          sdkToken: appData.data.sdk_token,
          settings: appData.data.settings,
          share: appData.data.share,
          appLocalization,
          isActive:
            appData.data.subscription_status === UserSubscriptionStatus.ACTIVE ||
            appData.data.subscription_status === UserSubscriptionStatus.TRIALING,
          ownerId: appData.data.user_id,
          plan: appData.data.plan || AppPlan.FREE,
          priceId: appData.data.price_id,
          collaboratorRole: appData.data.collaboration_role,
          subscriptionStatus: appData.data.subscription_status,
          subscriptionId: appData.data.subscription_id,
          trialStart: appData.data.subscription_trial_start,
          trialEnd: appData.data.subscription_trial_finish,
          subscriptionStart: appData.data.subscription_start,
          subscriptionEnd: appData.data.subscription_finish,
          isStartTrial: appData.data.price_id === process.env.REACT_APP_STRIPE_DEFAULT_PRICE_ID,
          storiesCount: 0,
          collaboratorsCount: 0
        };

        dispatch(appsManagerSlice.actions.createApp(payload));
        dispatch(appsManagerSlice.actions.selectApp(payload));
        dispatch(groupsSlice.actions.reset());

        return appData.data.id;
      }
    }

    return '';
  }
);

export const fetchUpdateApp = createAsyncThunk(
  'appsManager/fetchUpdateApp',
  async (
    params: {
      id: string;
      appLocalization?: any;
      title?: string;
      sdkToken?: string;
      iosAppId?: string;
      settings?: any;
      androidAppId?: string;
      settingAndroid?: any;
      settingIos?: any;
      share?: boolean;
      noNotification?: boolean;
    },
    { dispatch, getState }
  ) => {
    dispatch(appsManagerSlice.actions.setSavingStatus('saving'));
    const state = getState() as RootState;
    const app = state.appManager.current;
    const { data } = await API.apps.update({ ...app, ...params });

    if (data.data && !data.error && app) {
      dispatch(
        appsManagerSlice.actions.updateApp({
          ...app,
          ...params
        })
      );

      if (params.id === state.appManager.current?.id && params.settings) {
        dispatch(
          appsManagerSlice.actions.setSettings({
            ...app.settings,
            ...params.settings
          })
        );
      }

      if (params.id === state.appManager.current?.id && params.title) {
        dispatch(appsManagerSlice.actions.setTitle(params.title));
      }

      if (!params.noNotification) {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SUCCESS,
            text: i18n.t('notification.apps.updated')
          })
        );
      }
    } else if (!params.noNotification) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.apps.updateError')
        })
      );
    }
  }
);

export const fetchGetLocales = createAsyncThunk(
  'appsManager/fetchGetLocales',
  async (params, { dispatch }) => {
    const { data } = await API.apps.getLocales();

    if (data.data && !data.error) {
      dispatch(
        appsManagerSlice.actions.initLocales(
          data.data.map((item: any) => ({
            name: item.name,
            shortName: item.short_name,
            icon: item.icon
          }))
        )
      );
    }
  }
);

export const fetchGetApp = createAsyncThunk(
  'appsManager/fetchGetApp',
  async (params: { appId: string }, { dispatch, getState }) => {
    const { data } = await API.apps.get(params);
    const state = getState() as RootState;

    if (data.data && !data.error) {
      const appLocalization = {
        ...data.data.localization,
        languages: data.data.localization.languages.map((langItem: string) =>
          state.appManager.locales?.find((locale: any) => locale.shortName === langItem)
        )
      };

      dispatch(
        appsManagerSlice.actions.selectApp({
          id: data.data.id,
          title: data.data.title,
          sdkToken: data.data.sdk_token,
          iosAppId: data.data.ios_app_id,
          androidAppId: data.data.android_app_id,
          settingAndroid: data.data.setting_android,
          settingIos: data.data.setting_ios,
          settings: data.data.settings,
          share: data.data.share,
          appLocalization,
          ownerId: data.data.user_id,
          isActive:
            data.data.subscription_status === UserSubscriptionStatus.ACTIVE ||
            data.data.subscription_status === UserSubscriptionStatus.TRIALING,
          plan: data.data.plan || AppPlan.FREE,
          priceId: data.data.price_id,
          collaboratorRole: data.data.collaboration_role,
          subscriptionStatus: data.data.subscription_status,
          subscriptionId: data.data.subscription_id,
          trialStart: data.data.subscription_trial_start,
          trialEnd: data.data.subscription_trial_finish,
          subscriptionStart: data.data.subscription_start,
          subscriptionEnd: data.data.subscription_finish,
          isStartTrial: data.data.price_id === process.env.REACT_APP_STRIPE_DEFAULT_PRICE_ID,
          storiesCount: data.data.stories_count.all_stories_count,
          publishedStoriesCount: data.data.stories_count.active_stories_count,
          collaboratorsCount: data.data.collaborators_count
        })
      );
    }
  }
);

export const fetchGetApps = createAsyncThunk(
  'appsManager/fetchGetApps',
  async (params, { dispatch, getState }) => {
    dispatch(appsManagerSlice.actions.setStatus('pending'));
    const { data } = await API.apps.getList();

    if (data.data && !data.error) {
      const apps: AppBase[] = data.data.map((app: any) => ({
        id: app.id,
        title: app.title,
        ownerId: app.user_id
      }));

      dispatch(appsManagerSlice.actions.initApps(apps));

      if (apps.length) {
        return apps[0].id;
      }
    }
    return '';
  }
);

export const fetchRemoveApp = createAsyncThunk(
  'appsManager/fetchRemoveApp',
  async (params: { id?: string; subscriptionId?: string }, { dispatch, getState }) => {
    const state = getState() as RootState;

    const removeApp = async () => {
      if (!params.id) {
        return;
      }

      const { data } = await API.apps.remove({ id: params.id });
      if (data.data && !data.error) {
        dispatch(appsManagerSlice.actions.removeApp(params.id));

        const leftApps = state.appManager.appsList.filter((item: any) => item.id !== params.id);

        dispatch(groupsSlice.actions.reset());

        if (leftApps.length) {
          dispatch(fetchGetApp({ appId: leftApps[0].id }));
        } else {
          dispatch(appsManagerSlice.actions.reset());
        }

        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SUCCESS,
            text: i18n.t('notification.apps.deleted')
          })
        );
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: i18n.t('notification.apps.deleteError')
          })
        );
      }
    };
    if (params.id && params.subscriptionId) {
      dispatch(fetchCancelSubscription({ subscriptionId: params.subscriptionId })).then(removeApp);
    } else if (params.id) {
      removeApp();
    }
  }
);
