import React from 'react';
import {
  StoryRenderingFallback,
  StoryPreview,
  STORY_WIDTH,
  StoriesTemplateBase,
  useGetTemplateStories,
  GroupsType,
  Templates
} from '@features';
import block from 'bem-cn';
import { ErrorBoundary } from 'react-error-boundary';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '@store';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getActiveStoriesFlattenedArr } from '@utils';
import { HeaderButton } from '@components';
import { FreshButton } from '@components/_fresh';
import { useCreateStoriesFromTemplate } from '@hooks';
import './EditorTemplateItems.scss';

const b = block('EditorTemplateItems');

interface EditorTemplateItemsProps {
  template: StoriesTemplateBase;
  onBack: () => void;
}

export const EditorTemplateItems: React.FC<EditorTemplateItemsProps> = ({ template, onBack }) => {
  const { appId, groupId, templateId, type } = useParams<{
    appId?: string;
    groupId?: string;
    templateId?: string;
    type?: GroupsType;
    category?: Templates.Category;
  }>();

  const dispatch = useAppDispatch();
  const openTab = useSelector((store: RootState) => store.stories.editor.openTab);
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const stories = useGetTemplateStories(template.id);
  const currentStories = getActiveStoriesFlattenedArr(stories ?? {});

  const handleChooseStory = (layersGroupId: string) => {
    const story = stories?.[layersGroupId];

    if (story) {
      createFromTemplate([story], openTab?.tab === 'templates');

      if (openTab?.tab === 'templates') {
        dispatch(currentStoriesSlice.actions.setOpenTab(undefined));
      }
    }
  };

  const currentGroup = useSelector((store: RootState) =>
    store.groups.groups.find((group) => group.id === groupId)
  );

  const currentOnboarding = useSelector((store: RootState) =>
    store.onboardings.onboardings.find((group) => group.id === groupId)
  );

  const currentTemplate = useSelector((store: RootState) =>
    store.templates.templates.find((group) => group.id === templateId)
  );

  const getCurrentGroup = () => {
    if (type === GroupsType.TEMPLATE) {
      return currentTemplate;
    }

    if (type === GroupsType.ONBOARDING) {
      return currentOnboarding;
    }

    return currentGroup;
  };

  const createFromTemplate = useCreateStoriesFromTemplate({
    currentAppId: appId,
    currentGroup: getCurrentGroup(),
    templateSettings: template?.settings
  });

  const handleChooseAll = () => {
    const allStories = Object.values(stories ?? {});
    createFromTemplate(allStories);
  };

  const cardWidth = 96;

  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('headerTop')}>
        <HeaderButton
          color="transparent-white"
          icon="back-fresh"
          iconVariant="editor"
          onClick={onBack}
        >
          {t('editor.header.back')}
        </HeaderButton>

        <FreshButton color="dark" size="sm" text={t('editor.addAll')} onClick={handleChooseAll} />
      </div>
      <SimpleBar className={b('content')}>
        <div className={b('headerBottom')}>
          <p className={b('title')}>{template.title}</p>
          <p className={b('description')}>{template.description}</p>
        </div>
        <div className={b('container')}>
          {currentStories.map((story) => (
            <div
              className={b('item')}
              key={`editor-story-${story.id}`}
              onClick={() => handleChooseStory(story.layerData.layersGroupId)}
            >
              <ErrorBoundary FallbackComponent={StoryRenderingFallback}>
                <StoryPreview scaleIndex={cardWidth / STORY_WIDTH} story={story} />
              </ErrorBoundary>
            </div>
          ))}
        </div>
      </SimpleBar>
    </div>
  );
};
