import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Auth, createAxiosResponseInterceptor } from '@utils';
import { App } from './App';
import 'emoji-mart/css/emoji-mart.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './i18n/i18n';
import '@storysdk/core/dist/bundle.css';
import './index.scss';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
createAxiosResponseInterceptor();

if (Auth.isLoggedIn && Auth.accessToken) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${Auth.accessToken}`
  };
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
