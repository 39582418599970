import React, { useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { fetchOnboardings, GroupsLine, groupsSlice, GroupsType, LocaleSelector } from '@features';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useGroup } from '@features/groups/hooks';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { Tabs, TabType } from '@components';
import { GroupStoriesTab, OnboardingSettingsTab } from '../../components';

import './AppOnboardingRoute.scss';

const b = block('AppOnboardingRoute');

export const AppOnboardingRoute: React.FC = () => {
  const { appId, groupId } = useParams<{ appId: string; groupId: string }>();

  const groupsList = useSelector((store: RootState) => store.onboardings);

  const storiesGroup = useGroup(groupId, GroupsType.ONBOARDING);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const tabsItems: TabType[] = [
    {
      id: 'stories',
      title: t('dashboard.stories.title'),
      component: GroupStoriesTab,
      props: {
        type: GroupsType.ONBOARDING
      }
    },
    {
      id: 'settings',
      title: t('dashboard.onboardings.title'),
      component: OnboardingSettingsTab
    }
  ];

  const [tabs, setTabs] = useState(tabsItems);

  useEffect(() => {
    if (!storiesGroup) {
      setTabs(tabsItems.filter((item) => item.id !== 'settings'));
    } else {
      setTabs(tabsItems);
    }
  }, [storiesGroup]);

  const dashboardRiderectLink = useMemo(
    () =>
      `/dashboard/${appId}/onboarding/${groupsList.onboardings.length ? groupsList.onboardings[0].id : ''
      }`,
    [appId, groupsList.onboardings]
  );

  const handleGroupsLoad = (id: string) => {
    dispatch(fetchOnboardings({ appId: id }));
  };

  const handleGroupSelect = (id: string) => {
    history.push(`/dashboard/${appId}/onboarding/${id}`);

    dispatch(groupsSlice.actions.select(id));
  };

  return (
    <div className={b()}>
      <div className={b('groups')}>
        <GroupsLine
          currentAppId={groupsList.appId ?? undefined}
          groups={groupsList.onboardings}
          loadStatus={groupsList.status}
          redirectLink={dashboardRiderectLink}
          type={GroupsType.ONBOARDING}
          onLoad={handleGroupsLoad}
          onSelect={handleGroupSelect}
        />
      </div>
      <div className={b('tabs')}>
        <Tabs tabs={tabs} tabsLineControl={<LocaleSelector isRight theme="light" />} />
      </div>
    </div>
  );
};
