import React, { useCallback, useEffect, useState } from 'react';
import block from 'bem-cn';
import hexOpacity from 'hex-opacity';
import './GradientPicker.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { Picker } from '../Picker/Picker';

const b = block('GradientPicker');

interface Props {
  gradient: string[];
  isOpen?: boolean;
  onChange(gradient: string[]): void;
}

const GradientPicker: React.FC<Props> = (props) => {
  const { gradient, isOpen, onChange } = props;
  const [currentColor, setCurrentColor] = useState<'first' | 'second'>('first');
  const [gradientState, setGradient] = useState({
    first: gradient[0],
    second: gradient[1],
    changed: false
  });
  const selectedLayersGroupId = useSelector(
    (store: RootState) => store.stories.editor.selectedLayersGroupId
  );

  useEffect(() => {
    if (isOpen) {
      setGradient({
        first: gradient[0],
        second: gradient[1],
        changed: false
      });
    }
  }, [isOpen, selectedLayersGroupId]);

  const handleChangeColor = useCallback(
    (colorParams) => {
      setGradient({
        ...gradientState,
        [currentColor]: hexOpacity.create(colorParams.hex, colorParams.rgb.a),
        changed: true
      });
    },
    [gradientState, currentColor]
  );

  const handleChangeTwoColors = useCallback((colorParams: Array<string>) => {
    setGradient({
      first: colorParams[0],
      second: colorParams[1],
      changed: true
    });
  }, []);

  useEffect(() => {
    if (gradientState.changed) {
      onChange([gradientState.first, gradientState.second]);
    }
  }, [gradientState]);

  return (
    <div className={b()}>
      <div
        className={b('selector')}
        style={{
          background: `linear-gradient(90deg, ${gradientState.first} 0%, ${gradientState.second} 100%)`
        }}
      >
        <button
          className={b('color-btn', { current: currentColor === 'first' })}
          onClick={() => setCurrentColor('first')}
        >
          first
        </button>
        <button
          className={b('color-btn', { current: currentColor === 'second' })}
          onClick={() => setCurrentColor('second')}
        >
          second
        </button>
      </div>
      <Picker
        color={gradientState[currentColor]}
        type="gradient"
        onChange={handleChangeColor}
        onChangeGradient={handleChangeTwoColors}
      />
    </div>
  );
};

export default GradientPicker;
