import React, { useEffect } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import {
  DEFAULT_APP_PLAN,
  fetchInitSubscription,
  fetchInitSubscriptions,
  subscriptionsSlice
} from '@features';
import Skeleton from 'react-loading-skeleton';
import { AppPlan } from '@features/user/types';
import { AppOrderCard } from './components';
import './OrderSummaryTab.scss';
import { SubscriptionCancelledCard } from './components/SubscriptionCancelledCard';

const b = block('OrderSummaryTab');

interface OrderSummaryTabProps {
  appId: string;
}

export const OrderSummaryTab: React.FC<OrderSummaryTabProps> = () => {
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.user.user);
  const apps = useSelector((state: RootState) => state.appManager);
  const subscriptions = useSelector((state: RootState) => state.subscriptions);
  const currentApp = apps.current;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchInitSubscriptions());
  }, []);

  useEffect(() => {
    if (!currentApp) return;

    if (!currentApp.subscriptionId) {
      if (currentApp.plan === AppPlan.FREE) {
        dispatch(subscriptionsSlice.actions.setCurrentSubscription(DEFAULT_APP_PLAN));
      } else {
        dispatch(subscriptionsSlice.actions.setCurrentSubscription(null));
      }

      dispatch(subscriptionsSlice.actions.setCurrentSubscriptionStatus('loaded'));
      return;
    }

    if (currentApp.subscriptionId !== subscriptions.currentSubscription?.id) {
      dispatch(subscriptionsSlice.actions.setCurrentSubscriptionStatus('loading'));
      dispatch(fetchInitSubscription({ subscriptionId: currentApp.subscriptionId }));
    }
  }, [currentApp]);

  return (
    <div className={b()}>
      <div className={b('col')}>
        <div className={b('header')}>
          <p className={b('title')}>{t('dashboard.billing.addToBilling')}</p>
          <p className={b('desc')}>{t('dashboard.billing.addToBillingDesc')}</p>
        </div>
        <div className={b('cards')}>
          {currentApp &&
            currentApp.ownerId === user.id &&
            subscriptions.currentSubscriptionStatus === 'loaded' ? (
            <>
              <AppOrderCard
                app={currentApp}
                subscription={subscriptions.currentSubscription ?? undefined}
              />
              {subscriptions.currentSubscription?.status === 'canceled' && (
                <SubscriptionCancelledCard subscription={subscriptions.currentSubscription} />
              )}
            </>
          ) : (
            <Skeleton
              height={218}
              style={{
                borderRadius: 12
              }}
              width="100%"
            />
          )}
        </div>
      </div>
    </div>
  );
};
