import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { FreshInput } from '@components/_fresh';
import './SearchSelect.scss';

const b = block('SearchSelect');

interface SearchSelectProps {
  ref?: React.RefObject<HTMLDivElement>;
  selectedValue?: string;
  className?: string;
  listCount?: number;
  options: {
    value: string;
    label: string;
    icon?: string;
  }[];
  onChange: (value: string) => void;
}

export const SearchSelect: React.FC<SearchSelectProps> = ({
  selectedValue,
  options,
  className,
  ref,
  listCount,
  onChange
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = ({ value }: { value: string }) => {
    setSearchValue(value);
  };

  const { t } = useTranslation();

  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()))
    );
  }, [searchValue, options]);

  return (
    <div className={`${b().toString()} ${className ?? ''}`} ref={ref}>
      <div className={b('searchContainer')}>
        <FreshInput
          className={b('searchInput').toString()}
          placeholder={t('dashboard.header.search')}
          type="search"
          value={searchValue}
          onChange={handleSearch}
        />
      </div>
      <SimpleBar
        className={b('options')}
        style={{
          minHeight: listCount ? `${listCount * 44}px` : undefined
        }}
      >
        {filteredOptions.map((option) => (
          <div
            className={b('optionsItem', { selected: option.value === selectedValue })}
            onClick={() => onChange(option.value)}
          >
            {option.icon && (
              <div className={b('icon')} dangerouslySetInnerHTML={{ __html: option.icon }} />
            )}
            <span>{option.label}</span>
          </div>
        ))}
      </SimpleBar>
    </div>
  );
};
