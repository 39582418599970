import { WidgetContentType } from '@features/stories';
import { GoogleFont } from '@features/stories/editorSettings/components/SettingsFontSelector/SettingsFontSelector';
import { App, AppBase } from './App';

// eslint-disable-next-line no-shadow
export enum AppGroupStyle {
  CIRCLE = 'circle',
  SQUARE = 'square',
  BIG_SQUARE = 'bigSquare',
  RECTANGLE = 'rectangle'
}

export interface AppSettings {
  isShowMockup?: boolean;
  groupView?: {
    ios: AppGroupStyle;
    android: AppGroupStyle;
    react: AppGroupStyle;
    web: AppGroupStyle;
  };
  fonts: GoogleFont[];
  defaultWidgetsContent?: {
    [key: string]: Partial<WidgetContentType>;
  };
}

export type LocaleType = {
  name: string;
  icon: string;
  shortName: string;
};

export type SavingStatus = 'not_saved' | 'saving' | 'saved';
export interface AppsState {
  appsList: AppBase[];
  isFirstUsing?: boolean;
  currentLocale: string | null;
  current: App | null;
  status: string;
  savingStatus: SavingStatus;
  share?: boolean;
  locales?: LocaleType[];
}

export interface FormState {
  name: string;
  localization: App['appLocalization'];
}
