import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetAccessByCollaboratorRole, AppCollaborationFeatures } from '@features';
import { Tabs, TabType } from '@components';
import { AppSettingsTab } from './components/AppSettingsTab/AppSettingsTab';
import { LocalizationTab } from './components/LocalizationTab/LocalizationTab';
import { StyleTab } from './components/StyleTab/StyleTab';
import { TeamTab } from './components/TeamTab';
import { SetupTab } from './components/SetupTab';
import './AppSettingsRoute.scss';

const b = block('AppSettingsRoute');

export const AppSettingsRoute: React.FC = () => {
  const { tab } = useParams<{ tab?: string }>();

  const { t } = useTranslation();

  const tabsItems: TabType[] = [
    {
      id: 'AppSettingsTab',
      title: t('dashboard.apps.nav.settings'),
      component: AppSettingsTab
    },
    {
      id: 'Setup',
      title: t('dashboard.apps.nav.setup'),
      component: SetupTab
    },
    {
      id: 'Team',
      title: t('dashboard.apps.nav.team'),
      component: TeamTab
    },
    {
      id: 'Style',
      title: t('dashboard.apps.nav.style'),
      component: StyleTab
    },
    {
      id: 'Localization',
      title: t('dashboard.apps.nav.localization'),
      component: LocalizationTab
    }
    // {
    //   id: 'TestDeviceTab',
    //   title: 'Test device',
    //   component: TestDeviceTab
    // },
  ];

  const isTeamAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.INVITE_COLLABORATORS
  });

  const isStyleAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.CHANGE_APP_STYLE
  });

  const isLocalizationAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.ADD_APP_LOCALIZATION
  });

  const [tabs, setTabs] = useState(tabsItems);

  useEffect(() => {
    setTabs(
      tabsItems.filter((item) => {
        if (item.id === 'Team') {
          return isTeamAvailable;
        }

        if (item.id === 'Style') {
          return isStyleAvailable;
        }

        if (item.id === 'Localization') {
          return isLocalizationAvailable;
        }

        return true;
      })
    );
  }, [isTeamAvailable, isStyleAvailable, isLocalizationAvailable]);

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <Tabs initialTabId={tab || 'AppSettingsTab'} tabs={tabs} />
      </div>
    </div>
  );
};
