import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '@store';
import { App, AppLimits, LimitsByPlan, Subscription } from '@features';
import { productsSlice } from '@features/products/productsSlice';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { AppPlan } from '@features/user/types';
import { FreshActionOutput, FreshButton } from '@components/_fresh';
import { useGetUserSubscription } from '@hooks';
import { IconInfoWarning } from '@components';
import { SubscriptionStatusModal } from '../SubscriptionStatusModal';
import './AppOrderCard.scss';

const b = block('AppOrderCard');

interface AppOrderCardProps {
  app: App;
  subscription?: Subscription;
}

export const AppOrderCard: React.FC<AppOrderCardProps> = ({ app, subscription }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const userEmail = useSelector((state: RootState) => state.user.user.email);
  const userSubcription = useGetUserSubscription();

  const price = useMemo(() => {
    if (!subscription) return null;

    const isCouponValid =
      subscription.coupon?.valid &&
      (subscription.coupon?.duration === 'forever' ||
        (subscription?.coupon?.duration === 'repeating' &&
          subscription.coupon?.durationInMonths > 0));

    const amountWithDiscount = isCouponValid
      ? subscription.lastPaymentTotal
      : subscription.plan?.amount;

    const amount = amountWithDiscount ? +amountWithDiscount : 0;
    const currency = subscription.plan?.currency;
    const totalAmount = amount / 100;
    const interval =
      subscription.plan?.interval === 'month' || subscription.plan?.name === AppPlan.FREE
        ? t('plans.month')
        : t('plans.year');

    if (currency === 'eur') return `${totalAmount} € / ${interval}`;
    if (currency === 'usd') return `$${totalAmount} / ${interval}`;

    return `${totalAmount} ${currency} / ${interval}`;
  }, [subscription]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getPlanLimits = (plan: AppPlan) =>
    `${!LimitsByPlan[plan][AppLimits.STORIES_COUNT]
      ? '∞'
      : LimitsByPlan[plan][AppLimits.STORIES_COUNT]
    } ${t('user.stories')} / ${!LimitsByPlan[plan][AppLimits.APPS_COUNT] ? '∞' : LimitsByPlan[plan][AppLimits.APPS_COUNT]
    } ${t('user.project')} / ${!LimitsByPlan[plan][AppLimits.COLLABORATORS_COUNT]
      ? '∞'
      : LimitsByPlan[plan][AppLimits.COLLABORATORS_COUNT]
    } ${t('user.seats')}`;

  const descriptionEl = useMemo(() => {
    if (subscription?.plan?.name) {
      return (
        <>
          <span>
            {subscription?.plan?.interval === 'month'
              ? t('dashboard.billing.billedMonthly')
              : t('dashboard.billing.billedAnnually')}
          </span>
          <span className={b('limitsText')}>{getPlanLimits(subscription.plan.name)}</span>
        </>
      );
    }

    return null;
  }, [subscription, t]);

  const getSubscriptionStatus = () => {
    if (userSubcription.isTrialing) {
      return t('dashboard.billing.trial');
    }

    if (subscription?.status === 'active' || subscription?.status === 'trialing') {
      return t('dashboard.billing.active');
    }

    if (subscription?.status === 'past_due') {
      return t('dashboard.billing.pastDue');
    }

    return t('dashboard.billing.inactive');
  };

  if (!subscription || subscription?.status === 'canceled') {
    return (
      <div className={b()}>
        <div className={b('titleContainer')}>
          <p className={b('title')}>{t('dashboard.billing.subscription')}</p>
          <p className={b('desc')}>{t('dashboard.billing.choosePlanDesc')}</p>
        </div>
        <FreshButton
          className={b('button')}
          size="md"
          text={t('dashboard.billing.choosePlan')}
          onClick={() => {
            dispatch(productsSlice.actions.setPlansModalType('create'));
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className={b()}>
        <div className={b('titleContainer')}>
          <p className={b('title')}>
            {t('dashboard.billing.subscription')} - {subscription?.plan?.name}
          </p>
          <p className={b('desc')}>{subscription?.plan?.description}</p>
        </div>

        <FreshActionOutput
          action={() => {
            dispatch(productsSlice.actions.setPlansModalType('update'));
          }}
          actionText={t('dashboard.billing.change')}
          className={b('actionContainer').toString()}
          descriptionEl={descriptionEl}
          theme="transparent"
          value={`${subscription?.plan?.name ?? app.plan} (${price})`}
        />

        {subscription?.plan?.name !== AppPlan.FREE && (
          <div className={b('info')}>
            {!userSubcription.isTrialing && (
              <p className={b('infoItem')}>
                {t('dashboard.billing.nextPaymentIs')}{' '}
                <span className={b('infoItemValue')}>
                  {DateTime.fromSeconds(subscription.billingCycleAnchor).toLocaleString({
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </span>
              </p>
            )}

            {subscription.defaultPaymentMethod && (
              <p className={b('infoItem')}>
                {t('dashboard.billing.paymentMethod')}{' '}
                <span className={b('infoItemValue')}>
                  {subscription.defaultPaymentMethod?.type === 'card'
                    ? t('dashboard.billing.creditCard')
                    : subscription.defaultPaymentMethod?.type}
                </span>
                <a
                  className={b('infoItemLink')}
                  href={`${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${userEmail}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('dashboard.billing.change')}
                </a>
              </p>
            )}

            <p className={b('infoItem')}>
              {t('dashboard.billing.statusSubscription')}{' '}
              <span className={b('infoItemValue')}>{getSubscriptionStatus()}</span>
              {(subscription.status === 'active' ||
                subscription.status === 'paused' ||
                (subscription.status === 'trialing' && !userSubcription.isTrialing)) && (
                  <a
                    className={b('infoItemLink')}
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    {t('dashboard.billing.change')}
                  </a>
                )}
            </p>

            {userSubcription.isUnpaid && (
              <div className={b('infoItemContainer')}>
                <IconInfoWarning className={b('icon').toString()} />
                <p className={b('infoItem')}>
                  {t('dashboard.billing.additional')}{' '}
                  <span className={b('infoItemValue')}>{t('dashboard.billing.billingIssue')}</span>
                </p>
              </div>
            )}

            {userSubcription.isTrialing && subscription.trialEnd && (
              <p className={b('infoItem')}>
                {t('dashboard.billing.trialEndDay')}{' '}
                <span className={b('infoItemValue')}>
                  {DateTime.fromSeconds(subscription.trialEnd).toLocaleString({
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </span>
              </p>
            )}
          </div>
        )}

        <div className={b('buttonContainer')}>
          {(userSubcription.isTrialing || userSubcription.isUnpaid) && (
            <FreshButton
              className={b('button')}
              size="md"
              text={t('dashboard.billing.payNow')}
              onClick={() => {
                dispatch(productsSlice.actions.setPlansModalType('update'));
              }}
            />
          )}

          {userSubcription.isUnpaid && (
            <FreshButton
              className={b('button')}
              size="md"
              text={t('dashboard.billing.goToBilling')}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${userEmail}`,
                  '_blank'
                );
              }}
            />
          )}
        </div>
      </div>

      <SubscriptionStatusModal
        isOpen={isModalOpen}
        subscriptionId={subscription.id}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};
