import { ImageIcon } from '@modules/EditorModule/widgets';
import GradientPicker from '@modules/EditorModule/widgets/_components/ColorPicker/_components/GradientPicker/GradientPicker';
import MediaLoader from '@modules/EditorModule/widgets/_components/ColorPicker/_components/MediaLoader/MediaLoader';
import { Picker } from '@modules/EditorModule/widgets/_components/ColorPicker/_components/Picker/Picker';
import {
  BackgroundColorType,
  BackgroundFillType,
  BackgroundType,
  MediaType
} from '@storysdk/react';
import { BackgroundBlob } from '@features/stories/types';
import block from 'bem-cn';
import React, { useEffect, useState } from 'react';
import { ColorChangeHandler } from 'react-color';
import hexOpacity from 'hex-opacity';
import './BackgroundSelector.scss';
import { useParams } from 'react-router-dom';
import { GroupsType } from '@features';
import { BackgroundColorTemplates } from './_components';

interface BackgroundSelectorProps {
  value: BackgroundType;
  availableTypes: BackgroundFillType[];
  isGrey?: boolean;
  isTemplatesModeOn?: boolean;
  initialTab?: BackgroundColorType | MediaType;
  onChange(value: BackgroundType | BackgroundBlob): void;
}

type GradientValue = { type: BackgroundColorType.GRADIENT; value: string[] };

export const BackgroundSelector: React.FC<BackgroundSelectorProps> = ({
  value,
  availableTypes,
  initialTab,
  isGrey,
  isTemplatesModeOn,
  onChange
}) => {
  const b = block('BackgroundSelector');

  const [currentTab, setTab] = useState(
    initialTab ?? value.type === MediaType.VIDEO ? MediaType.IMAGE : value.type
  );
  const { type } = useParams<{
    type: GroupsType;
  }>();

  useEffect(() => {
    setTab(initialTab ?? value.type === MediaType.VIDEO ? MediaType.IMAGE : value.type);
  }, [value.type, initialTab]);

  const handleChangeColorHex = (value: string) => {
    onChange({
      type: BackgroundColorType.COLOR,
      value
    });
  };

  const handleChangeColor: ColorChangeHandler = (params) => {
    onChange({
      type: BackgroundColorType.COLOR,
      value: hexOpacity.create(params.hex, params.rgb.a)
    });
  };

  const handleChangeGradient = (gradient: GradientValue['value']) => {
    onChange({ type: BackgroundColorType.GRADIENT, value: gradient });
  };

  const handleChangeImage = (image: BackgroundBlob['value']) => {
    onChange({ type: MediaType.IMAGE, value: image });
  };

  const handleChangeVideo = (video: BackgroundBlob['value'], metadata?: any) => {
    onChange({ type: MediaType.VIDEO, value: video, metadata });
  };

  const handleChangeFile = (fileType: MediaType, file: any, metadata?: any) => {
    switch (fileType) {
      case MediaType.IMAGE:
        handleChangeImage(file);
        break;
      case MediaType.VIDEO:
        handleChangeVideo(file, metadata);
        break;
    }
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        {availableTypes.includes(BackgroundColorType.COLOR) && (
          <button
            className={b('tabControl', {
              current: currentTab === BackgroundColorType.COLOR,
              type: BackgroundColorType.COLOR
            })}
            onClick={() => {
              setTab(BackgroundColorType.COLOR);
            }}
          >
            .
          </button>
        )}

        {availableTypes.includes(BackgroundColorType.GRADIENT) && (
          <button
            className={b('tabControl', {
              current: currentTab === BackgroundColorType.GRADIENT,
              type: BackgroundColorType.GRADIENT
            })}
            onClick={() => setTab(BackgroundColorType.GRADIENT)}
          >
            .
          </button>
        )}

        {(availableTypes.includes(MediaType.IMAGE) || availableTypes.includes(MediaType.VIDEO)) && (
          <button
            className={b('tabControl', {
              current: currentTab === MediaType.IMAGE,
              type: MediaType.IMAGE
            })}
            onClick={() => setTab(MediaType.IMAGE)}
          >
            <ImageIcon color={currentTab === MediaType.IMAGE ? '#FF00D0' : '#474764'} />
          </button>
        )}
      </div>

      <div className={b('tabs', { grey: isGrey })}>
        <div className={b('tab', { current: currentTab === BackgroundColorType.COLOR })}>
          {isTemplatesModeOn ? (
            <BackgroundColorTemplates
              groupType={type}
              type="color"
              onChange={handleChangeColorHex}
            />
          ) : (
            <Picker
              color={value.type === BackgroundColorType.COLOR ? (value.value as string) : '#FFFFFF'}
              type="color"
              onChange={handleChangeColor}
            />
          )}
        </div>
        <div className={b('tab', { current: currentTab === BackgroundColorType.GRADIENT })}>
          {isTemplatesModeOn ? (
            <BackgroundColorTemplates
              groupType={type}
              type="gradient"
              onChange={handleChangeGradient}
            />
          ) : (
            <GradientPicker
              gradient={
                value.type === BackgroundColorType.GRADIENT
                  ? (value.value as string[])
                  : ['#FFFFFF', '#000000']
              }
              isOpen={currentTab === BackgroundColorType.GRADIENT}
              onChange={handleChangeGradient}
            />
          )}
        </div>
        <div
          className={b('tab', {
            current: currentTab === MediaType.IMAGE
          })}
        >
          <MediaLoader
            availableTypes={[MediaType.IMAGE, MediaType.VIDEO].filter((mediaType) =>
              availableTypes.includes(mediaType)
            )}
            value={value.type === MediaType.IMAGE ? (value.value as string) : null}
            onLoad={handleChangeFile}
          />
        </div>
      </div>
    </div>
  );
};
