import { useState, useRef, useEffect } from 'react';

export function useVisible(initialIsVisible: boolean) {
  const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);
  const additionalRef = useRef<HTMLElement>(null);

  const handleClickOutside = (event: any) => {
    if (additionalRef.current) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        additionalRef.current &&
        !additionalRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    } else if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible, additionalRef };
}
