import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import {
  BondsType,
  checkIsNotInput,
  EditorMode,
  getStoryObject,
  GroupPositionType,
  GroupsType,
  sortByString,
  Story,
  StoryEditorPreviewInner
} from '@features';
import Moveable, { OnSnap } from 'react-moveable';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesSlice, useCurrentStoriesType } from '@features/stories/hooks';
import {
  SelectedWidgetMode,
  StoryEditorAction,
  useStoryEditor,
  useStoryEditorDispatch
} from '@modules';
import { getWidgetPositionLimits } from '@features/stories/consts';
import { WidgetObjectType, WidgetPositionType, WidgetsTypes } from '@storysdk/react';
import { useDebounce } from '@hooks';
import { StoryEditorMoveable } from './_components/StoryEditorMoveable';
import { WidgetContextMenu } from './_components/WidgetContextMenu';
import './StoryEditorInner.scss';

type StoryStateType = Story & {
  isOrderChanged?: boolean;
};
interface StoryEditorProps {
  story: StoryStateType;
  scale: number;
  editorRef: RefObject<HTMLDivElement>;
  isAltPressed?: boolean;
  isShiftPressed?: boolean;
  isLayerMoving?: boolean;
  topBarHeight?: number;
  bottomBarHeight?: number;
  isHidden?: boolean;
  isShouldShowBorder?: boolean;
  presetWidth: number;
  presetHeight: number;
  onBoundsChange?: (bounds: BondsType) => void;
  onLayerMoving: (isMoving: boolean) => void;
  onToogleSyncDesign?: (isActive: boolean) => void;
}

export const LARGE_DEFAULT_WINDOW_HEIGHT = 3205;
const ARROW_BUTTONS = ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'];

const StoryEditorInner: React.FC<StoryEditorProps> = ({
  story,
  editorRef,
  scale,
  isAltPressed,
  isShiftPressed,
  isLayerMoving,
  isShouldShowBorder,
  isHidden,
  presetWidth,
  topBarHeight,
  bottomBarHeight,
  presetHeight,
  onBoundsChange,
  onLayerMoving,
  onToogleSyncDesign
}) => {
  const dispatch = useAppDispatch();

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);
  const currentStoriesType = useCurrentStoriesType(type);

  const storyEditorDispatch = useStoryEditorDispatch();

  const editorMode = useSelector((store: RootState) => store[currentStoriesType].editor.editorMode);
  const storyEditorState = useStoryEditor();
  const selectedWidgetMode = storyEditorState?.selectedWidgetMode;
  const selectedWidgetIds = storyEditorState?.selectedWidgetsIds ?? [];
  const keepRatio = storyEditorState?.keepRatio ?? false;
  const widgetsGroupPosition = storyEditorState?.group;
  const [hoveredWidgetId, setHoveredWidgetId] = useState<string | null>(null);
  const [groupState, setGroupState] = useState<GroupPositionType | null>(null);
  const [contexWidgetIds, setContextWidgetIds] = useState<string[]>([]);
  const [isBorderVisible, setIsBorderVisible] = useState(false);
  const [isVerticalGuidlineVisible, setIsVerticalGuidlineVisible] = useState(false);

  const contextMenuRef = useRef<any>(null);

  const handleLayerMove = (isMoving: boolean) => {
    onLayerMoving(isMoving);
  };

  const handleWidgetChange = (payload: WidgetObjectType, isFirst?: boolean) => {
    storyEditorDispatch?.({
      type: StoryEditorAction.UPDATE_STORY_WIDGET,
      payload: {
        widget: payload,
        storyWidth: presetWidth,
        storyHeight: presetHeight,
        ignoreForPublish: isFirst
      }
    });
  };

  const handleGroupChange = (payload: GroupPositionType) => {
    setGroupState(payload);
  };

  const hoveredWidgets = story?.widgets?.filter((widget) => widget.id === hoveredWidgetId);
  const hoveredWidget = hoveredWidgets?.length ? hoveredWidgets[0] : undefined;
  const contextWidgets = story?.widgets?.filter((widget) => contexWidgetIds.includes(widget.id));
  const selectedWidgets = story?.widgets?.filter((widget) => selectedWidgetIds.includes(widget.id));
  const groupChanged = useDebounce(groupState, 100);
  const moveableRef = useRef<Moveable>(null);

  useEffect(() => {
    storyEditorDispatch?.({ type: StoryEditorAction.SET_GROUP, payload: groupChanged });
  }, [groupChanged]);

  useEffect(() => {
    if (isShiftPressed !== undefined) {
      storyEditorDispatch?.({ type: StoryEditorAction.SET_KEEP_RATIO, payload: isShiftPressed });
    }
  }, [isShiftPressed]);

  // START SET GROUP POSITION
  useEffect(() => {
    const widgetsInGroup = story?.widgets?.filter((widget) =>
      selectedWidgetIds.includes(widget.id)
    );

    if (!widgetsInGroup || widgetsInGroup.length < 2) {
      dispatch(currentStoriesSlice.actions.setSelectedGroup(null));
      return;
    }

    const groupPosition = {
      x1: widgetsInGroup[0].positionByResolutions[`${presetWidth}x${presetHeight}`].x,
      y1: widgetsInGroup[0].positionByResolutions[`${presetWidth}x${presetHeight}`].y,
      x2:
        widgetsInGroup[0].positionByResolutions[`${presetWidth}x${presetHeight}`].x +
        widgetsInGroup[0].positionByResolutions[`${presetWidth}x${presetHeight}`].realWidth,
      y2:
        widgetsInGroup[0].positionByResolutions[`${presetWidth}x${presetHeight}`].y +
        widgetsInGroup[0].positionByResolutions[`${presetWidth}x${presetHeight}`].realHeight,
      width: 0,
      height: 0,
      rotate: 0,
      center: {
        x: 0,
        y: 0
      }
    };
    widgetsInGroup.forEach((widget) => {
      const widgetPosition = widget.positionByResolutions[`${presetWidth}x${presetHeight}`];
      const widgetPositionWidth = widgetPosition.x + widgetPosition.realWidth;
      const widgetPositionHeight = widgetPosition.y + widgetPosition.realHeight;
      const groupPositionWidth = groupPosition.x1 + (groupPosition.x2 - groupPosition.x1);
      const groupPositionHeight = groupPosition.y1 + (groupPosition.y2 - groupPosition.y1);
      if (widgetPosition.x < groupPosition.x1) {
        groupPosition.x1 = widgetPosition.x;
      }
      if (widgetPosition.y < groupPosition.y1) {
        groupPosition.y1 = widgetPosition.y;
      }
      if (groupPositionWidth < widgetPositionWidth) {
        groupPosition.x2 = widgetPositionWidth;
      }
      if (groupPositionHeight < widgetPositionHeight) {
        groupPosition.y2 = widgetPositionHeight;
      }
    });
    groupPosition.width = groupPosition.x2 - groupPosition.x1;
    groupPosition.height = groupPosition.y2 - groupPosition.y1;

    setGroupState({
      x: groupPosition.x1,
      y: groupPosition.y1,
      width: groupPosition.width,
      height: groupPosition.height,
      rotate: groupPosition.rotate,
      lastAlign: widgetsGroupPosition?.lastAlign
    });
  }, [selectedWidgetIds, story?.widgets]);
  // END SET GROUP POSITION

  const handleMouseDown = (e: any) => {
    if (isHidden) {
      return;
    }

    const storyObject = getStoryObject(e.target);

    if (storyObject && storyObject.id && storyObject.widgetType) {
      onLayerMoving(true);
      dispatch(currentStoriesSlice.actions.setEditorMode(EditorMode.DESIGN));

      if (isShiftPressed && selectedWidgetMode !== SelectedWidgetMode.EDIT) {
        storyEditorDispatch?.({
          type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
          payload: [...selectedWidgetIds, storyObject.id]
        });
      } else if (
        (storyObject.isOriginal && storyObject.widgetType === WidgetsTypes.TEXT) ||
        storyObject.widgetType !== WidgetsTypes.TEXT
      ) {
        storyEditorDispatch?.({
          type: StoryEditorAction.SET_SELECTED_WIDGET_MODE,
          payload: SelectedWidgetMode.VIEW
        });

        storyEditorDispatch?.({
          type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
          payload: [storyObject.id]
        });
      }
    } else if (e.target?.className.includes('moveable-area')) {
      onLayerMoving(true);
    } else if (selectedWidgetIds.length && selectedWidgetMode === SelectedWidgetMode.EDIT) {
      storyEditorDispatch?.({
        type: StoryEditorAction.SET_SELECTED_WIDGET_MODE,
        payload: SelectedWidgetMode.VIEW
      });

      storyEditorDispatch?.({
        type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
        payload: selectedWidgetIds
      });
    } else {
      storyEditorDispatch?.({
        type: StoryEditorAction.SET_SELECTED_WIDGET_MODE,
        payload: SelectedWidgetMode.VIEW
      });

      storyEditorDispatch?.({
        type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
        payload: []
      });
    }

    if (e.target !== contextMenuRef.current) {
      setContextWidgetIds([]);
    }
  };

  const handleDoubleClick = (e: any) => {
    const storyObject = getStoryObject(e.target);
    const widgetType = storyObject?.widgetType;

    if (widgetType === WidgetsTypes.TEXT) {
      if (storyEditorState?.selectedWidgetMode !== SelectedWidgetMode.EDIT) {
        storyEditorDispatch?.({
          type: StoryEditorAction.SET_SELECTED_WIDGET_MODE,
          payload: SelectedWidgetMode.EDIT
        });
      }
    }
  };

  const handleOpenContextMenu = (e: any) => {
    e.preventDefault();
    const storyObject = getStoryObject(e.target);

    if (storyObject) {
      setContextWidgetIds([storyObject.id]);
    } else if (e.target?.className.includes('moveable-area')) {
      setContextWidgetIds(selectedWidgetIds);
    }
  };

  const handleMouseUp = (e: any) => {
    onLayerMoving(false);
  };

  const handleContextMenuRender = (menuElement: HTMLDivElement) => {
    contextMenuRef.current = menuElement;
  };

  const handleLayerDown = (widgetIds: string[]) => {
    if (!story) {
      return;
    }

    const newWidgets = story.widgets ? [...story.widgets] : [];

    story.widgets.forEach((widget, index) => {
      if (widgetIds.includes(widget.id)) {
        const prevWidget = newWidgets[index - 1];
        const currentWidget = newWidgets[index];

        if (prevWidget && !widgetIds.includes(prevWidget.id)) {
          newWidgets.splice(index, 1);
          newWidgets.splice(index - 1, 0, currentWidget);
        }
      }
    });

    storyEditorDispatch?.({
      type: StoryEditorAction.SET_STORY_WIDGETS,
      payload: { widgets: newWidgets, saveAll: true }
    });
  };

  const handleLayerUp = (widgetIds: string[]) => {
    if (!story) {
      return;
    }

    const newWidgets = story.widgets ? [...story.widgets] : [];

    story.widgets.forEach((widget, index) => {
      if (widgetIds.includes(widget.id)) {
        const nextWidget = newWidgets[index + 1];
        const currentWidget = newWidgets[index];

        if (nextWidget && !widgetIds.includes(nextWidget.id)) {
          newWidgets.splice(index, 1);
          newWidgets.splice(index + 1, 0, currentWidget);
        }
      }
    });

    storyEditorDispatch?.({
      type: StoryEditorAction.SET_STORY_WIDGETS,
      payload: { widgets: newWidgets, saveAll: true }
    });
  };

  const contextWidgetsIdsSorted = useMemo(() => sortByString([...contexWidgetIds]).join(','), [
    contexWidgetIds
  ]);
  const firstWidgetsIds = useMemo(
    () =>
      story
        ? sortByString(
          story.widgets?.map((widget) => widget.id).slice(0, contexWidgetIds.length)
        ).join(',')
        : '',
    [contexWidgetIds, story]
  );
  const lastWidgetsIds = useMemo(
    () =>
      story
        ? sortByString(
          story.widgets?.map((widget) => widget.id).slice(-contexWidgetIds.length)
        ).join(',')
        : '',
    [contexWidgetIds, story]
  );

  useEffect(() => {
    const isVisible =
      (!!isAltPressed && !isLayerMoving && selectedWidgetIds.length === 1) ||
      (isVerticalGuidlineVisible && !!isLayerMoving && !!story.background.isFilled);

    setIsBorderVisible(isVisible);
  }, [isAltPressed, isLayerMoving, selectedWidgetIds, isVerticalGuidlineVisible]);

  const handleSnap = (e: OnSnap) => {
    const isVertical = e.guidelines.some((guideline) => guideline.type === 'vertical');

    setIsVerticalGuidlineVisible(isVertical);
  };

  const handleChangeWidgetPosition = (
    widgetId: string,
    payload: Partial<WidgetPositionType>,
    isStopDesignSyncToggle?: boolean
  ) => {
    if (!isStopDesignSyncToggle && onToogleSyncDesign) {
      onToogleSyncDesign(false);
    }

    storyEditorDispatch?.({
      type: StoryEditorAction.UPDATE_STORY_WIDGET_POSITION,
      payload: {
        id: widgetId,
        position: payload,
        storyWidth: presetWidth,
        storyHeight: presetHeight,
        ignoreForPublish: isStopDesignSyncToggle
      }
    });
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isNotInput = checkIsNotInput(event);
      const changeValue = isShiftPressed ? 10 : 1;

      if (selectedWidgets && isNotInput && ARROW_BUTTONS.includes(event.key)) {
        event.preventDefault();
        onLayerMoving(true);

        selectedWidgets.forEach((currentWidget) => {
          let selectedWidgetPosition =
            currentWidget.positionByResolutions[`${presetWidth}x${presetHeight}`];

          if (event.key === 'ArrowUp') {
            selectedWidgetPosition = {
              ...selectedWidgetPosition,
              y: selectedWidgetPosition.y - changeValue,
              origin: {
                ...selectedWidgetPosition.origin,
                y: selectedWidgetPosition.origin.y - changeValue
              }
            };
          } else if (event.key === 'ArrowDown') {
            selectedWidgetPosition = {
              ...selectedWidgetPosition,
              y: selectedWidgetPosition.y + changeValue,
              origin: {
                ...selectedWidgetPosition.origin,
                y: selectedWidgetPosition.origin.y + changeValue
              }
            };
          } else if (event.key === 'ArrowLeft') {
            selectedWidgetPosition = {
              ...selectedWidgetPosition,
              x: selectedWidgetPosition.x - changeValue,
              origin: {
                ...selectedWidgetPosition.origin,
                x: selectedWidgetPosition.origin.x - changeValue
              }
            };
          } else if (event.key === 'ArrowRight') {
            selectedWidgetPosition = {
              ...selectedWidgetPosition,
              x: selectedWidgetPosition.x + changeValue,
              origin: {
                ...selectedWidgetPosition.origin,
                x: selectedWidgetPosition.origin.x + changeValue
              }
            };
          }

          if (onToogleSyncDesign) {
            onToogleSyncDesign(false);
          }

          storyEditorDispatch?.({
            type: StoryEditorAction.UPDATE_STORY_WIDGET_POSITION,
            payload: {
              id: currentWidget.id,
              position: selectedWidgetPosition,
              storyWidth: presetWidth,
              storyHeight: presetHeight
            }
          });
        });
      }
    },
    [isShiftPressed, onLayerMoving, selectedWidgets, storyEditorDispatch, onToogleSyncDesign]
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (ARROW_BUTTONS.includes(event.key)) {
        onLayerMoving(false);
      }
    },
    [onLayerMoving]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  useEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.moveable.updateRect();
    }
  }, [selectedWidgets]);

  return (
    <>
      <StoryEditorPreviewInner
        editorRef={editorRef}
        handleDoubleClick={handleDoubleClick}
        handleHover={!isLayerMoving ? setHoveredWidgetId : undefined}
        handleMouseDown={handleMouseDown}
        handleMouseUp={handleMouseUp}
        handleOpenContextMenu={handleOpenContextMenu}
        handleWidgetChange={handleWidgetChange}
        hoveredWidget={hoveredWidget}
        isAltPressed={isAltPressed && !isLayerMoving}
        isBorderVisible={isShouldShowBorder && isBorderVisible}
        isEditWidgetMode={selectedWidgetMode === SelectedWidgetMode.EDIT}
        presetHeight={presetHeight}
        presetWidth={presetWidth}
        scale={scale}
        selectedWidgetIds={selectedWidgetIds}
        widgets={story?.widgets ?? []}
      >
        {!!selectedWidgetIds?.length &&
          editorMode !== EditorMode.LOGIC &&
          selectedWidgetMode === SelectedWidgetMode.VIEW && (
            <StoryEditorMoveable
              bottomBarHeight={bottomBarHeight}
              container={editorRef.current}
              containerHeight={presetHeight}
              containerWidth={presetWidth}
              groupPosition={widgetsGroupPosition ?? null}
              isAltPressed={isAltPressed}
              isShiftPressed={isShiftPressed}
              keepRatio={
                selectedWidgets?.length === 1 &&
                (keepRatio ||
                  selectedWidgets[0].positionByResolutions[`${presetWidth}x${presetHeight}`]
                    .isHeightLocked) &&
                selectedWidgets[0].positionLimits.keepRatio !== false
              }
              moveableRef={moveableRef}
              positionLimits={
                selectedWidgets?.length
                  ? getWidgetPositionLimits(selectedWidgets[0].content.type)
                  : undefined
              }
              scaleIndex={scale}
              topBarHeight={topBarHeight}
              widgetIds={selectedWidgetIds}
              onBoundsChange={onBoundsChange}
              onChangeGroup={handleGroupChange}
              onChangeWidgetPosition={handleChangeWidgetPosition}
              onMove={handleLayerMove}
              onSnap={handleSnap}
            />
          )}
      </StoryEditorPreviewInner>

      {!!contextWidgets?.length && (
        <WidgetContextMenu
          isFirstLayer={contextWidgetsIdsSorted === firstWidgetsIds}
          isLastLayer={contextWidgetsIdsSorted === lastWidgetsIds}
          presetHeight={presetHeight}
          presetWidth={presetWidth}
          scaleIndex={scale}
          selectedWidgets={contextWidgets}
          widgetGroupPosition={widgetsGroupPosition ?? undefined}
          onClose={() => setContextWidgetIds([])}
          onLayerDown={handleLayerDown}
          onLayerUp={handleLayerUp}
          onRender={handleContextMenuRender}
        />
      )}
    </>
  );
};

export default StoryEditorInner;
