import { SIZE_PRESETS, STORY_HEIGHT_LARGE, STORY_WIDTH, SizePresetKeys } from '@features';
import { useMemo } from 'react';

export const useCurrentSizePreset = (currentGroup: any) => {
  const defaultPreset = useMemo(
    () =>
      Object.values(SIZE_PRESETS).find((preset) => preset.isDefault) ?? {
        title: SizePresetKeys.IphoneLarge,
        width: STORY_WIDTH,
        height: STORY_HEIGHT_LARGE
      },
    []
  );

  const currentSizePreset = useMemo(() => {
    if (!currentGroup?.settings?.sizePreset) {
      return defaultPreset;
    }

    return SIZE_PRESETS[currentGroup.settings.sizePreset];
  }, [currentGroup?.settings?.sizePreset, defaultPreset]);

  return currentSizePreset;
};
