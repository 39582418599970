import React from 'react';
import PropTypes from 'prop-types';

const IconDashboardTemplates = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.25838 2.00008H5.62916C3.62467 2.00008 1.99994 3.62518 1.99994 5.6293V9.25852C1.99994 9.8599 2.21689 10.267 2.81822 10.267C3.41959 10.267 3.65843 9.8791 3.65843 9.27777L3.64488 5.64588C3.64488 4.84409 4.86263 3.47759 5.6641 3.47759L9.30954 3.50469C9.91091 3.50469 10.3395 3.33357 10.3395 2.73224C10.3395 2.13091 9.85971 2.00008 9.25838 2.00008Z" />
    <path d="M14.702 2.00008H18.3312C20.3357 2.00008 21.9604 3.62518 21.9604 5.6293V9.25852C21.9604 9.8599 21.7435 10.267 21.1422 10.267C20.5408 10.267 20.302 9.8791 20.302 9.27777L20.3155 5.64588C20.3155 4.84409 19.0978 3.47759 18.2963 3.47759L14.6508 3.50469C14.0495 3.50469 13.6209 3.33357 13.6209 2.73224C13.6209 2.13091 14.1007 2.00008 14.702 2.00008Z" />
    <path
      clipRule="evenodd"
      d="M20.4333 16.5462L20.4195 14.6904C20.4195 14.089 20.5907 13.6605 21.192 13.6605C21.7933 13.6605 21.9242 14.1402 21.9242 14.7416V18.3708C21.9242 20.3753 20.2991 22 18.2949 22H15.7227H14.6657H5.62916C3.62504 22 1.99994 20.3753 1.99994 18.3708V14.7416C1.99994 14.1402 2.13077 13.6605 2.7321 13.6605C3.33343 13.6605 3.50455 14.089 3.50455 14.6904L3.49053 16.5763L8.11094 12.0115C8.42963 11.6927 9.22098 11.5104 9.53972 11.8291L13.2957 15.3241L15.4054 12.6724C15.7241 12.3536 16.2409 12.3536 16.5596 12.6724L20.2005 16.3133L20.4333 16.5462ZM20.4451 18.1223C20.2809 18.0144 20.1263 17.8572 20.0391 17.7684C20.0268 17.7559 20.0158 17.7447 20.0063 17.7353L19.3063 17.0575L18.1021 15.9242L16.0243 14.0116L14.2915 16.2484L14.904 16.8317L15.44 17.3808C15.4662 17.4071 15.4964 17.4352 15.5283 17.4649C15.7278 17.6509 15.997 17.9017 15.8234 18.178C15.8234 18.178 15.5276 18.648 15.1187 18.8155C14.7099 18.983 13.5185 17.7187 13.5185 17.7187L12.1689 16.5127L9.47151 14.1083C9.15283 13.7895 8.63602 13.7895 8.31729 14.1083L5.80403 16.6215L4.66138 17.7642L3.70123 18.6677C3.66233 18.7066 3.62488 18.7422 3.58679 18.7734C3.92646 19.5167 4.98049 20.3551 5.64574 20.3551L14.5641 20.3431C14.5911 20.342 14.6185 20.3415 14.6465 20.3415L14.9316 20.3426L15.7227 20.3415C15.7707 20.3415 15.8111 20.343 15.8447 20.346L18.2784 20.3551C19.0801 20.3551 20.4466 19.1373 20.4466 18.3359L20.4451 18.1223Z"
      fillRule="evenodd"
    />
    <path d="M13.8179 6.52475C13.5708 6.42765 13.3019 6.37428 13.0209 6.37428C11.8154 6.37428 10.8347 7.35499 10.8347 8.56042C10.8347 9.76589 11.8154 10.7466 13.0209 10.7466C13.3019 10.7466 13.5709 10.6932 13.8179 10.5961C14.1212 10.4769 14.3914 10.2919 14.6112 10.0587C14.9803 9.66716 15.207 9.13981 15.207 8.56042C15.207 7.98108 14.9803 7.45369 14.6112 7.0621C14.3914 6.82894 14.1211 6.64388 13.8179 6.52475ZM13.035 9.7455C12.3413 9.76271 11.764 9.32285 11.7741 8.56727C11.7391 7.69241 12.4466 7.27097 13.0557 7.30635C13.704 7.30846 14.1707 7.85803 14.1719 8.50526C14.1779 9.07506 13.8568 9.72953 13.035 9.7455Z" />
  </svg>

  // <svg
  //   className={className}
  //   fill="none"
  //   height="24"
  //   stroke="#A49BA4"
  //   viewBox="0 0 24 24"
  //   width="24"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     d="M5.16022 6.16005C5.62122 5.62005 6.14622 5.13905 6.72222 4.72205"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth="1.5"
  //   />
  //   <path
  //     d="M17.0002 19.4821C15.5702 20.4401 13.8512 21.0001 12.0002 21.0001C11.0882 21.0001 10.2092 20.8621 9.38025 20.6101"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth="1.5"
  //   />
  //   <path
  //     d="M6.34919 19C5.06819 17.965 4.08419 16.583 3.52319 15"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth="1.5"
  //   />
  //   <path
  //     d="M3.52322 9C3.19022 9.94 3.00022 10.946 3.00022 12"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth="1.5"
  //   />
  //   <path
  //     d="M19.4632 17C20.4272 15.569 21.0002 13.855 21.0002 12C21.0002 7.029 16.9712 3 12.0002 3"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth="1.5"
  //   />
  //   <path d="M12 9V15" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  //   <path d="M15 12H9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  // </svg>
);

IconDashboardTemplates.propTypes = {
  className: PropTypes.string
};

IconDashboardTemplates.defaultProps = {
  className: ''
};

export default IconDashboardTemplates;
