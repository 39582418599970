import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '@store';
import {
  AppCollaborationFeatures,
  Collabarator,
  CollabaratorRole,
  CollabaratorStatus,
  fetchCollaborators,
  fetchRemoveCollaborator,
  fetchResendInviteToCollaborator,
  useGetActionByAccess,
  useGetActionByCollaboratorRole
} from '@features';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppFeatures } from '@features/user/consts';
import { nanoid } from 'nanoid';
import { FreshButton, FreshTable, FreshTableDataRow } from '@components/_fresh';
import { IconDeleteFresh, IconEditFresh, IconResend } from '@components';
import { InviteTeamMemberModal } from './_components';
import { RemoveTeamMemberModal } from './_components/RemoveTeamMemberModal';
import './TeamTab.scss';

const b = block('TeamTab');
const s = block('GroupSettingsTab');

export const TeamTab: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { appId } = useParams<{ appId: string }>();

  const status = useSelector((store: RootState) => store.collaborators.status);
  const rows = useSelector((store: RootState) => store.collaborators.list);
  const currentUser = useSelector((store: RootState) => store.user.user);

  const [currentCollaborator, setCurrentCollaborator] = useState<Collabarator>();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [deleteCollaborator, setDeleteCollaborator] = useState<Collabarator>();

  useEffect(() => {
    dispatch(
      fetchCollaborators({
        appId
      })
    );
  }, [appId]);

  const teamTableHeaderCols = [
    {
      title: t('dashboard.team.user'),
      key: 'user'
    },
    {
      title: t('dashboard.team.role'),
      key: 'role'
    },
    {
      title: t('dashboard.team.action'),
      key: 'action'
    }
  ];

  const collaboratorsRoleMap = {
    [CollabaratorRole.OWNER]: t('dashboard.team.owner'),
    [CollabaratorRole.ADMIN]: t('dashboard.team.admin'),
    [CollabaratorRole.MEMBER]: t('dashboard.team.member')
  };

  const handleOpenDeleteCollaboratorModal = (collaborator: Collabarator) => {
    setDeleteCollaborator(collaborator);
  };

  const handleDeleteCollaborator = () => {
    if (deleteCollaborator) {
      dispatch(
        fetchRemoveCollaborator({
          appId,
          collaboratorId: deleteCollaborator.id
        })
      );

      setDeleteCollaborator(undefined);
    }
  };

  const handleEditCollaborator = (collaborator: Collabarator) => {
    setCurrentCollaborator(collaborator);
  };

  const handleResendInvite = (collaboratorId: string) => {
    dispatch(
      fetchResendInviteToCollaborator({
        appId,
        collaboratorId
      })
    );
  };

  const getCollaboratorRow = (collaborator: Collabarator): FreshTableDataRow => ({
    user: collaborator.email,
    role: `${collaboratorsRoleMap[collaborator.role]} ${collaborator.status === CollabaratorStatus.NEW ? `(${t('dashboard.team.waitText')})` : ''
      }`,
    action: (
      <div className={b('actions')}>
        {collaborator.status === CollabaratorStatus.NEW && (
          <button
            className={b('actionButton')}
            disabled={collaborator.role === CollabaratorRole.OWNER}
            onClick={() => handleResendInvite(collaborator.id)}
          >
            <IconResend className={b('actionIcon')} />
          </button>
        )}

        {collaborator.status === CollabaratorStatus.CONFIRM && (
          <button
            className={b('actionButton')}
            disabled={collaborator.role === CollabaratorRole.OWNER}
            onClick={() =>
              onActionClickCollaborator(AppCollaborationFeatures.EDIT_COLLABORATORS, () =>
                handleEditCollaborator(collaborator)
              )
            }
          >
            <IconEditFresh className={b('actionIcon', { stroke: true })} />
          </button>
        )}

        <button
          className={b('actionButton')}
          disabled={collaborator.role === CollabaratorRole.OWNER}
          onClick={() =>
            onActionClickCollaborator(AppCollaborationFeatures.REMOVE_COLLABORATORS, () =>
              handleOpenDeleteCollaboratorModal(collaborator)
            )
          }
        >
          <IconDeleteFresh className={b('actionIcon')} />
        </button>
      </div>
    )
  });

  const ownerData = {
    id: nanoid(),
    userId: currentUser?.id ?? '',
    email: currentUser?.email ?? '',
    role: CollabaratorRole.OWNER,
    appId,
    creatorId: '',
    status: CollabaratorStatus.CONFIRM,
    createdAt: '',
    updatedAt: ''
  };

  const collaboratorRows = [ownerData, ...rows].map((collaborator) =>
    getCollaboratorRow(collaborator)
  );

  const onActionClick = useGetActionByAccess();
  const onActionClickCollaborator = useGetActionByCollaboratorRole();

  return (
    <>
      <div>
        <div className={s('formHeader')}>
          <div className={s('formHeaderContent')}>
            <h1 className={s('formHeaderTitle')}>{t('dashboard.team.title')}</h1>
          </div>

          <FreshButton
            size="md"
            text={t('dashboard.team.inviteUser')}
            onClick={() =>
              onActionClickCollaborator(AppCollaborationFeatures.INVITE_COLLABORATORS, () => {
                onActionClick(AppFeatures.COLLABORATORS, () => {
                  setIsInviteModalOpen(true);
                });
              })
            }
          />
        </div>

        <FreshTable
          alignStart
          dataRows={collaboratorRows ?? []}
          emptyText={t('dashboard.team.noData')}
          headerCols={teamTableHeaderCols}
          isLoading={status === 'pending'}
        />
      </div>

      <InviteTeamMemberModal
        data={
          currentCollaborator
            ? {
              id: currentCollaborator.id,
              email: currentCollaborator.email,
              role: currentCollaborator.role
            }
            : undefined
        }
        isOpen={isInviteModalOpen || !!currentCollaborator}
        onClose={() => {
          setCurrentCollaborator(undefined);
          setIsInviteModalOpen(false);
        }}
      />

      <RemoveTeamMemberModal
        handleRemoveTeamMember={handleDeleteCollaborator}
        isOpen={!!deleteCollaborator}
        title={deleteCollaborator?.email ?? ''}
        onClose={() => {
          setDeleteCollaborator(undefined);
        }}
      />
    </>
  );
};
