import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import {
  storiesAnalyticsGroupSlice,
  fetchAnalyticsStory,
  StoryAnalyticsCard,
  StoryAnalyticsModal
} from '@features';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { StoryMock } from '@mocks';
import './AnalyticsInteractionsTab.scss';

const b = block('AnalyticsInteractionsTab');
const d = block('DashboardStories');

export const AnalyticsInteractionsTab = () => {
  const dispatch = useAppDispatch();

  const { appId, groupId } = useParams<{ appId: string; groupId?: string }>();

  const groupData = useSelector((store: RootState) => store.storiesAnalyticsGroup.data);
  const loadStatus = useSelector((store: RootState) => store.storiesAnalyticsGroup.status);

  const { t } = useTranslation();

  const [isMoreOpen, setMoreOpen] = useState<boolean>(false);

  const handleMoreOpen = (storyId: string) => {
    setMoreOpen(true);

    if (groupData && groupData.stories.length && appId && groupId && groupId !== 'all') {
      dispatch(fetchAnalyticsStory({ appId, groupId, storyId }));
    }
  };

  const handleMoreClose = () => {
    setMoreOpen(false);
    dispatch(storiesAnalyticsGroupSlice.actions.setCurrentStory(null));
  };

  return (
    <div className={b()}>
      {loadStatus === 'pending' ? (
        <div className={b('stories')}>
          {Array.from(Array(4)).map((_, index) => (
            <StoryAnalyticsCard isLoading key={`mock-analytic-card-${index}`} story={StoryMock} />
          ))}
        </div>
      ) : (
        <>
          {groupData && groupData.stories.length ? (
            <>
              <div className={b('stories')}>
                {[...groupData.stories]
                  .sort((storyA, storyB) => (storyA.position < storyB.position ? -1 : 1))
                  .map((story) => (
                    <StoryAnalyticsCard
                      key={`story-analytic-card-${story.id}`}
                      story={story}
                      onOpenModal={handleMoreOpen}
                    />
                  ))}
              </div>

              <StoryAnalyticsModal isOpen={isMoreOpen} onClose={handleMoreClose} />
            </>
          ) : (
            <div className={b('stories')}>
              <div className={cn(d('storyItem').toString(), b('storyItem').toString())}>
                <div className={b('emptyStory')}>
                  <p className={b('emptyStoryTitle')}>{t('dashboard.analytics.groups.noData')}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
