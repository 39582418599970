import React from 'react';
import { Field, reduxForm } from 'redux-form';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { validators, renderLightField } from '@utils';
import { FormGroup, IconGoogle } from '@components';
import { FreshButton, FreshCheckbox } from '@components/_fresh';
import i18n from '../../i18n/i18n';

import './SignUpForm.scss';

const signUpValidate = (values: any) => {
  const errors: any = {};

  if (!values.privacyPolicy) {
    errors.privacyPolicy = i18n.t('form.required');
  }

  return errors;
};

const b = block('SignUpForm');

export const SignUpForm = reduxForm({
  form: 'signUp',
  validate: signUpValidate
})((props) => {
  const { handleSubmit, submitting, invalid, pristine } = props;

  const { t } = useTranslation();

  return (
    <form className={b()} onSubmit={handleSubmit}>
      <div className={b('socialContainer')}>
        <FreshButton
          className={b('btn')}
          color="transparent-grey-light"
          leftIcon={<IconGoogle className={b('btnIcon').toString()} />}
          text={t('auth.signUpWithGoogle')}
        />
      </div>

      <Field
        component={renderLightField}
        label={t('auth.email')}
        name="email"
        placeholder={t('auth.emailPlaceholder')}
        type="email"
        validate={[validators.required, validators.email]}
      />
      <Field
        component={renderLightField}
        label={t('auth.fullName')}
        name="full_name"
        placeholder={t('auth.fullNamePlaceholder')}
        type="text"
        validate={[validators.required]}
      />
      <Field
        autocomplete="off"
        component={renderLightField}
        label={t('auth.password')}
        name="password"
        placeholder={t('auth.passwordPlaceholder')}
        type="password"
        validate={[validators.required, validators.password]}
      />
      <Field
        className={b('privacy')}
        component={({ name, input, meta: { error, warning } }: any) => (
          <FormGroup>
            <FreshCheckbox
              invalid={!!error}
              label={
                <>
                  {t('auth.accept')}{' '}
                  <a
                    href="https://legal.storysdk.com/privacy-policy"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('auth.privacyPolicy')}
                  </a>
                </>
              }
              name={name}
              value={input.value}
              onChange={input.onChange}
            />
            {error &&
              ((
                <span
                  style={{
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#EE2E2E',
                    display: 'block',
                    marginTop: '12px'
                  }}
                >
                  {t('auth.privacyPolicyAgreement')}
                </span>
              ) ||
                (warning && <span>{warning}</span>))}
          </FormGroup>
        )}
        name="privacyPolicy"
        validate={[validators.required]}
        value={false}
      />

      <FreshButton
        className={b('btn').toString()}
        disabled={invalid || submitting || pristine}
        text={t('auth.next')}
        type="submit"
      />
    </form>
  );
});
