import React, { useState, useEffect, memo } from 'react';
import block from 'bem-cn';
import { Scrubber } from '@components';
import './SettingsInput.scss';

const b = block('SettingsInput');

interface Props {
  type: 'text' | 'number';
  icon?: React.ReactNode;
  prefix?: string;
  postfix?: string;
  value: string | number;
  onChange?(value: string | number): void;
  disabled?: boolean;
  min?: number;
  max?: number;
  className?: string;
  inputClassName?: string;
  grow?: boolean;
}

const MAX_VALUE = 9999;
export const MAX_ANGLE_VALUE = 360;
export const MIN_ANGLE_VALUE = -360;

export const SettingsInput: React.FC<Props> = memo((props) => {
  const {
    onChange,
    icon,
    prefix,
    postfix,
    type,
    grow,
    value,
    disabled,
    min,
    max,
    inputClassName,
    className
  } = props;

  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [tempValue, setTempValue] = useState<string | number>(value);

  useEffect(() => {
    if (!isFirst && value !== tempValue) {
      setTempValue(value);
    }
    setIsFirst(false);
  }, [value]);

  useEffect(() => {
    if (!isFirst && tempValue !== value) {
      let newValue = tempValue;

      if (type === 'number') {
        newValue = tempValue !== undefined ? +tempValue : 0;
      }

      if (type === 'text') {
        newValue = tempValue !== undefined ? tempValue : '';
      }

      onChange?.(newValue);
    }
    setIsFirst(false);
  }, [tempValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(event.target.value);
  };

  const handleChangeScrub = (num: number) => {
    setTempValue(num);
  };

  const wrapScrub = (content: React.ReactNode) => {
    if (type === 'number') {
      return (
        <Scrubber
          disabled={disabled}
          max={max || MAX_VALUE}
          min={min || 0}
          steps={1}
          value={tempValue !== undefined ? +tempValue : 100}
          onChange={handleChangeScrub}
          onScrubEnd={() => { }}
        >
          {content}
        </Scrubber>
      );
    }

    return content;
  };

  return (
    <div
      className={`${b({
        withIcon: !!icon,
        withPrefix: !!prefix,
        withPostfix: !!postfix,
        grow: !!grow,
        disabled
      })} ${className || ''}`}
    >
      {prefix && wrapScrub(<div className={b('prefix')}>{prefix}</div>)}
      {icon && wrapScrub(<div className={b('icon')}>{icon}</div>)}
      <input
        className={`${b('input')} ${inputClassName || ''}`}
        disabled={disabled}
        max={max || MAX_VALUE}
        min={min || 0}
        type={type}
        value={tempValue}
        onChange={handleChange}
      />
      {postfix && <div className={b('postfix')}>{postfix}</div>}
    </div>
  );
});
