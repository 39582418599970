import React, { useCallback, useEffect } from 'react';
import {
  GroupItem,
  GroupsType,
  StoriesGroup,
  StoriesTemplateBase,
  StoriesTemplatesContainer,
  StoriesTemplatesItemContainer,
  Story,
  STORY_WIDTH,
  StoryLayer,
  StoryLayers,
  StoryPreview,
  Templates,
  useFetchTemplates,
  useGetTemplateStories
} from '@features';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { FreshButton } from '@components/_fresh';
import { useCreateStoriesFromTemplate, useIsMobile } from '@hooks';
import { IconCloseCircle } from '@components';

import './ChooseTemplatePage.scss';

const b = block('ChooseTemplatePage');

interface ChooseTemplatePageProps {
  currentGroup: StoriesGroup;
  templateId?: string;
  onSelectTemplate: (templateId: string) => void;
}

const CHOSEN_STORY_WIDTH = 38;

export const ChooseTemplatePage: React.FC<ChooseTemplatePageProps> = ({
  currentGroup,
  templateId,
  onSelectTemplate
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  useFetchTemplates({
    category:
      currentGroup.type === GroupsType.GROUP
        ? Templates.Category.STORIES
        : Templates.Category.ONBOARDING,
    isWithStories: true,
    isOnlyActive: true
  });

  const currentAppId = useSelector((store: RootState) => store.appManager.current?.id) ?? '';
  const currentGroupId = useSelector((store: RootState) => store.groups.current) ?? '';
  const currentOnboardingId = useSelector((store: RootState) => store.onboardings.current) ?? '';

  const [currentTemplate, setCurrentTemplate] = React.useState<StoriesTemplateBase | null>(null);

  const currentTemplateStories = useGetTemplateStories(currentTemplate?.id);

  const [chosenStories, setChosenStories] = React.useState<StoryLayer[]>([]);
  const [isContinueDisabled, setIsContinueDisabled] = React.useState<boolean>(false);

  const templates = useSelector((store: RootState) => store.templates);

  useEffect(() => {
    if (templateId) {
      setCurrentTemplate(
        templates.templates.find((template) => template.id === templateId) ?? null
      );
    } else {
      setCurrentTemplate(null);
    }
  }, [templateId, templates.templates]);

  const history = useHistory();

  const handleGoToEditor = useCallback(() => {
    history.push(
      `/editor/${currentAppId}/${currentGroup.type === GroupsType.GROUP ? 'group' : 'onboarding'}/${currentGroup.type === GroupsType.GROUP ? currentGroupId : currentOnboardingId
      }`
    );
  }, [currentAppId, currentGroupId, history, currentGroup]);

  const handleGoToDashboard = useCallback(() => {
    history.push(
      `/dashboard/${currentAppId}/${currentGroup.type === GroupsType.GROUP ? 'stories' : 'onboarding'
      }/${currentGroup.type === GroupsType.GROUP ? currentGroupId : currentOnboardingId}`
    );
  }, [currentAppId, currentGroupId, history, currentGroup]);

  const chooseTemplate = useCallback(
    (templateID, stories) => {
      if (currentTemplate?.id === templateID) {
        setChosenStories([]);
        setCurrentTemplate(null);
      } else {
        setChosenStories(stories);
        setCurrentTemplate(
          templates.templates.find((template) => template.id === templateID) ?? null
        );
      }
    },
    [currentTemplate, templates.templates]
  );

  const createFromTemplate = useCreateStoriesFromTemplate({
    currentAppId,
    currentGroup,
    templateSettings: currentTemplate?.settings,
    onBeforeCreating: () => setIsContinueDisabled(true),
    onAfterCreating: isMobile ? handleGoToDashboard : handleGoToEditor
  });

  const chooseStory = useCallback(
    (story: Story) => {
      if (chosenStories.find((currentStory) => currentStory.id === story.layerData.layersGroupId)) {
        setChosenStories((prev) =>
          prev.filter((prevStory) => prevStory.id !== story.layerData.layersGroupId)
        );
      } else if (currentTemplate && currentTemplateStories) {
        const storyLayer = Object.values(currentTemplateStories).find(
          (currentLayer) => currentLayer.id === story.layerData.layersGroupId
        );

        if (!storyLayer) {
          return;
        }

        setChosenStories((prev) => [...prev, storyLayer]);
      }
    },
    [chosenStories, currentTemplate]
  );

  const chooseAllStories = useCallback(() => {
    if (chosenStories.length) {
      setChosenStories([]);
    } else if (currentTemplate && currentTemplateStories) {
      setChosenStories(Object.values(currentTemplateStories));
    }
  }, [chosenStories.length, currentTemplate]);

  useEffect(() => {
    if (templates.status === 'loaded' && !templates.templates.length) {
      if (isMobile) {
        handleGoToDashboard();
      } else {
        handleGoToEditor();
      }
    }
  }, [handleGoToEditor, handleGoToDashboard, templates]);

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('groupContainer')}>
          <GroupItem group={currentGroup} hoveringGroup={null} isActive isDisabled />
          {chosenStories.length > 0 && (
            <div className={b('chosenStories')}>
              {chosenStories.map((story) => (
                <div className={b('chosenStory')} key={story.id}>
                  <button
                    className={b('chosenStoryRemoveBtn')}
                    onClick={() => chooseStory(story.layers[story.activeLayerId])}
                  >
                    <IconCloseCircle />
                  </button>
                  <div className={b('chosenStoryPreview')}>
                    <StoryPreview
                      isOnboarding={currentGroup.type === GroupsType.ONBOARDING}
                      scaleIndex={CHOSEN_STORY_WIDTH / STORY_WIDTH}
                      story={story.layers[story.activeLayerId]}
                      templateVariant
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={b('btnsContainer')}>
          <FreshButton
            className={b('btn').toString()}
            disabled={!chosenStories.length || isContinueDisabled}
            size="lg"
            text={`${t('dashboard.templates.continue')} (${chosenStories.length})`}
            onClick={() => createFromTemplate(chosenStories)}
          />
          <button
            className={b('altBtn')}
            onClick={isMobile ? handleGoToDashboard : handleGoToEditor}
          >
            {t('dashboard.templates.skipStep')}
          </button>
        </div>
      </div>
      <div className={b('container')}>
        {!templateId ? (
          <StoriesTemplatesContainer
            choosenTemplateId={currentTemplate?.id ?? ''}
            chosenStoryIds={chosenStories.map((story) => story.layers[story.activeLayerId].id)}
            isLoading={templates.status === 'pending' || templates.status === 'idle'}
            templates={templates.templates}
            onChooseTemplate={(currentTemplateId: string, stories: StoryLayers) => {
              chooseTemplate(currentTemplateId, Object.values(stories));
            }}
            onSelectTemplate={onSelectTemplate}
          />
        ) : (
          <StoriesTemplatesItemContainer
            chosenStories={chosenStories.map((story) => story.layers[story.activeLayerId])}
            stories={currentTemplateStories ?? undefined}
            templateId={templateId}
            onBack={() => onSelectTemplate('')}
            onChooseAllStories={chooseAllStories}
            onChooseStory={chooseStory}
          />
        )}
      </div>
    </div>
  );
};
