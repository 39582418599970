import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { appsManagerSlice } from '@features/appsManagerSlice';
import { RootState } from '@store';
import { AppPlan } from '@features/user/types';
import { Subscription, SubscriptionsState } from './types';

const initialState: SubscriptionsState = {
  subscriptions: [],
  currentSubscription: null,
  status: 'idle',
  currentSubscriptionStatus: 'idle'
};

export const DEFAULT_APP_PLAN: Subscription = {
  id: '',
  trialEnd: undefined,
  status: 'active',
  subscriptionItemId: undefined,
  cancelAtPeriodEnd: false,
  canceledAt: 0,
  appId: '',
  appTitle: '',
  billingCycleAnchor: 0,
  currency: 'usd',
  currentPeriodEnd: 0,
  currentPeriodStart: 0,
  lastPaymentTotal: 0,
  defaultPaymentMethod: {
    type: '',
    brand: undefined,
    last4: undefined
  },
  plan: {
    id: '',
    amount: 0,
    interval: 'year',
    currency: 'usd',
    name: AppPlan.FREE,
    description: 'Perfect for small teams or for using stories widget for site or mobile apps'
  }
};

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<SubscriptionsState>) => {
      state = action.payload;
    },
    setSubscriptions: (state, action: PayloadAction<Subscription[]>) => {
      state.subscriptions = action.payload;
    },
    setCurrentSubscription: (state, action: PayloadAction<Subscription | null>) => {
      state.currentSubscription = action.payload;
    },
    updateCurrentSubscription: (state, action: PayloadAction<Partial<Subscription>>) => {
      state.currentSubscription = { ...state.currentSubscription!, ...action.payload };
    },
    setStatus: (state, action: PayloadAction<SubscriptionsState['status']>) => {
      state.status = action.payload;
    },
    setCurrentSubscriptionStatus: (
      state,
      action: PayloadAction<SubscriptionsState['currentSubscriptionStatus']>
    ) => {
      state.currentSubscriptionStatus = action.payload;
    }
  }
});

export const fetchInitSubscriptions = createAsyncThunk(
  'subscriptions/fetchInitSubscriptions',
  async (params, { dispatch }) => {
    dispatch(subscriptionsSlice.actions.setStatus('loading'));

    const { data: subscriptionsData } = await API.subscription.subscriptions();

    if (subscriptionsData.data && !subscriptionsData.error) {
      dispatch(
        subscriptionsSlice.actions.setSubscriptions(
          subscriptionsData.data.map((subscription: any) => ({
            id: subscription.id,
            currency: subscription.currency,
            currentPeriodEnd: subscription.current_period_end,
            currentPeriodStart: subscription.current_period_start,
            status: subscription.status,
            cancelAtPeriodEnd: subscription.cancel_at_period_end,
            canceledAt: subscription.canceled_at,
            billingCycleAnchor: subscription.billing_cycle_anchor,
            appTitle: subscription.metadata.app_title,
            appId: subscription.metadata.app_id,
            plan: subscription.items.data.length
              ? {
                id: subscription.items.data[0].plan.id,
                amount: subscription.items.data[0].plan.amount,
                interval: subscription.items.data[0].plan.interval,
                currency: subscription.items.data[0].plan.currency
              }
              : undefined
          }))
        )
      );
      dispatch(subscriptionsSlice.actions.setStatus('loaded'));
    } else {
      dispatch(subscriptionsSlice.actions.setStatus('loaded'));
    }
  }
);

export const fetchCreateSubscription = createAsyncThunk(
  'subscriptions/fetchCreateSubscription',
  async (params: { appId: string; priceId: string }, { dispatch }) => {
    const { data } = await API.subscription.createSubscription({
      appId: params.appId,
      priceId: params.priceId
    });

    dispatch(
      subscriptionsSlice.actions.setCurrentSubscription({
        id: data.data.id,
        currency: data.data.currency,
        currentPeriodEnd: data.data.current_period_end,
        currentPeriodStart: data.data.current_period_start,
        status: data.data.status,
        cancelAtPeriodEnd: data.data.cancel_at_period_end,
        canceledAt: data.data.canceled_at,
        billingCycleAnchor: data.data.billing_cycle_anchor,
        appTitle: data.data.metadata.app_title,
        appId: data.data.metadata.app_id,
        lastPaymentTotal: data.data.latest_invoice.total,
        plan: {
          id: data.data.items.data[0].plan.id,
          amount: data.data.items.data[0].plan.amount,
          interval: data.data.items.data[0].plan.interval,
          currency: data.data.items.data[0].plan.currency
        }
      })
    );
  }
);

export const fetchSetFreeSubscription = createAsyncThunk(
  'subscriptions/fetchSetFreeSubscription',
  async (params: { appId: string; nextUpgrade?: boolean }, { dispatch, getState }) => {
    const { data } = await API.subscription.setFreeSubscription({
      appId: params.appId,
      nextUpgrade: params.nextUpgrade
    });

    const state = getState() as RootState;

    if (data.data && !data.error) {
      const currentApp = state.appManager.current;

      if (currentApp) {
        dispatch(
          appsManagerSlice.actions.updateApp({
            ...currentApp,
            plan: AppPlan.FREE,
            subscriptionId: ''
          })
        );
      }

      dispatch(subscriptionsSlice.actions.updateCurrentSubscription(DEFAULT_APP_PLAN));
    }
  }
);

export const fetchUpdateSubscriptionItem = createAsyncThunk(
  'subscriptions/fetchUpdateSubscriptionItem',
  async (
    params: { appId: string; subscriptionId: string; priceId: string; subscriptionItemId: string },
    { dispatch, getState }
  ) => {
    const { data } = await API.subscription.updateSubscriptionItem({
      subscriptionId: params.subscriptionId,
      priceId: params.priceId,
      subscriptionItemId: params.subscriptionItemId
    });

    const state = getState() as RootState;

    const currentApp = state.appManager.current;

    if (currentApp && currentApp.id === params.appId) {
      dispatch(
        appsManagerSlice.actions.updateApp({
          ...currentApp,
          plan: data.data.plan.product.name,
          subscriptionId: data.data.subscription
        })
      );
    }

    dispatch(
      subscriptionsSlice.actions.updateCurrentSubscription({
        id: data.data.subscription,
        subscriptionItemId: data.data.id,
        plan: {
          id: data.data.plan.id,
          amount: data.data.plan.amount,
          interval: data.data.plan.interval,
          currency: data.data.plan.currency,
          name: data.data.plan.product.name,
          description: data.data.plan.product.description
        }
      })
    );
  }
);

export const fetchPauseSubscription = createAsyncThunk(
  'subscriptions/fetchPauseSubscription',
  async (params: { subscriptionId: string }, { dispatch }) => {
    const { data } = await API.subscription.pauseSubscription({
      subscriptionId: params.subscriptionId
    });

    dispatch(subscriptionsSlice.actions.updateCurrentSubscription({ status: data.data.status }));
  }
);

export const fetchCancelSubscription = createAsyncThunk(
  'subscriptions/fetchCancelSubscription',
  async (params: { subscriptionId: string }, { dispatch }) => {
    const { data } = await API.subscription.cancelSubscription({
      subscriptionId: params.subscriptionId
    });

    dispatch(subscriptionsSlice.actions.updateCurrentSubscription({ status: data.data.status }));
    dispatch(appsManagerSlice.actions.updateSubscriptionStatus(data.data.status));
  }
);

export const fetchResumeSubscription = createAsyncThunk(
  'subscriptions/fetchResumeSubscription',
  async (params: { subscriptionId: string }, { dispatch }) => {
    const { data } = await API.subscription.resumeSubscription({
      subscriptionId: params.subscriptionId
    });

    dispatch(subscriptionsSlice.actions.updateCurrentSubscription({ status: data.data.status }));
  }
);

export const fetchInitSubscription = createAsyncThunk(
  'subscriptions/fetchInitSubscriptions',
  async (params: { subscriptionId: string }, { dispatch }) => {
    const { data: subscriptionData } = await API.subscription.subscription({
      subscriptionId: params.subscriptionId
    });

    dispatch(subscriptionsSlice.actions.setCurrentSubscriptionStatus('loading'));

    if (subscriptionData.data && !subscriptionData.error) {
      const appPlan = subscriptionData.data.items.data.length
        ? subscriptionData.data.items.data[0].plan
        : null;

      const subscriptionItemId = subscriptionData.data.items.data.length
        ? subscriptionData.data.items.data[0].id
        : null;

      dispatch(
        subscriptionsSlice.actions.setCurrentSubscription({
          id: subscriptionData.data.id,
          currency: subscriptionData.data.currency,
          currentPeriodEnd: subscriptionData.data.current_period_end,
          currentPeriodStart: subscriptionData.data.current_period_start,
          status: subscriptionData.data.status,
          cancelAtPeriodEnd: subscriptionData.data.cancel_at_period_end,
          canceledAt: subscriptionData.data.canceled_at,
          appTitle: subscriptionData.data.metadata.app_title,
          appId: subscriptionData.data.metadata.app_id,
          billingCycleAnchor: subscriptionData.data.billing_cycle_anchor,
          trialEnd: subscriptionData.data.trial_end,
          subscriptionItemId,
          lastPaymentTotal: subscriptionData.data.latest_invoice.total,
          coupon: subscriptionData.data.latest_invoice.discount.coupon
            ? {
              duration: subscriptionData.data.latest_invoice.discount.coupon.duration,
              valid: subscriptionData.data.latest_invoice.discount.coupon.valid,
              durationInMonths:
                subscriptionData.data.latest_invoice.discount.coupon.duration_in_months
            }
            : undefined,
          defaultPaymentMethod: subscriptionData.data.default_payment_method
            ? {
              type: subscriptionData.data.default_payment_method.type,
              brand: subscriptionData.data.default_payment_method.card.brand,
              last4: subscriptionData.data.default_payment_method.card.last4
            }
            : undefined,
          plan: appPlan
            ? {
              id: appPlan.id,
              amount: appPlan.amount,
              interval: appPlan.interval,
              currency: appPlan.currency,
              name: appPlan.product.name,
              description: appPlan.product.description
            }
            : undefined
        })
      );

      dispatch(subscriptionsSlice.actions.setCurrentSubscriptionStatus('loaded'));
    } else {
      dispatch(subscriptionsSlice.actions.setCurrentSubscriptionStatus('loaded'));
    }
  }
);
