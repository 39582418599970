import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthModule, DashboardModule, EditorModule, AccountModule, SharingModule } from '@modules';
import { FontsContainer } from '@features';
import { LocaleWrapper } from '@hocs';
import { Auth } from '@utils';
import { MessagesContainer } from './features/inform/index';
import reduxStore from './store';

export const App: React.FC = () => {
  useEffect(() => {
    Auth.refreshAccessToken();
  }, []);

  return (
    <ReduxProvider store={reduxStore}>
      <LocaleWrapper>
        <Router>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify
            }}
          >
            <AuthModule />
            <DashboardModule />
            <EditorModule />
            <AccountModule />
            <SharingModule />
          </QueryParamProvider>
          <MessagesContainer />
        </Router>

        <FontsContainer />
      </LocaleWrapper>
    </ReduxProvider>
  );
};
