import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { AppCollaborationFeatures, useRedirectByAccessCollaborator } from '@features';
import { useGetCurrentApp } from '@features/stories/hooks';
import { Tabs, TabType } from '@components';
import { OrderSummaryTab } from '../../components';
import './AppBillingRoute.scss';

const b = block('AppBillingRoute');

export const AppBillingRoute: React.FC = () => {
  const userEmail = useSelector((state: RootState) => state.user.user.email);
  const { t } = useTranslation();

  const currentApp = useGetCurrentApp();

  useRedirectByAccessCollaborator({
    feature: AppCollaborationFeatures.BILLING_APP,
    app: currentApp ?? undefined
  });

  const tabsItems: TabType[] = [
    {
      id: 'summary',
      title: t('dashboard.billing.orderSummary'),
      component: OrderSummaryTab
    },
    {
      id: 'billing',
      title: t('user.billing'),
      link: `${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${userEmail}`
    }
  ];

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <Tabs tabs={tabsItems} />
      </div>
    </div>
  );
};
