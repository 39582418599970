import React, { memo } from 'react';
import block from 'bem-cn';
import './HeaderButton.scss';
import { Icon } from '@components';

const b = block('HeaderButton');

interface Props {
  color: 'primary' | 'accent' | 'transparent' | 'transparent-black' | 'transparent-white';
  onClick?(): void;
  icon?: string;
  iconVariant?: string;
  disabled?: boolean;
}

export const HeaderButton: React.FC<Props> = memo((props) => {
  const { children, color, onClick, icon, iconVariant, disabled } = props;

  return (
    <button className={b({ color })} disabled={disabled} onClick={onClick}>
      {icon && <Icon className={b('icon').toString()} name={icon} variant={iconVariant} />}
      {children}
    </button>
  );
});
