import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import { ColorResult, CustomPicker } from 'react-color';
import { roundToPercent } from '@modules/EditorModule/utils';
import SimpleBar from 'simplebar-react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components';
import { ColorExample } from '../ColorExample/ColorExample';
import { ColorExampleGradient } from '../ColorExampleGradient/ColorExampleGradient';
import 'simplebar-react/dist/simplebar.min.css';
import './Picker.scss';

const b = block('Picker');

const modes = ['hex', 'rgb', 'hsl'];

export const colorExamples = [
  { type: 'color', value: 'rgba(241, 120, 205, 1)' },
  { type: 'color', value: 'rgba(26, 107, 232, 1)' },
  { type: 'color', value: 'rgba(0, 159, 232, 1)' },
  { type: 'color', value: 'rgba(3, 202, 107, 1)' },
  { type: 'color', value: 'rgba(150, 45, 119, 1)' },
  { type: 'color', value: 'rgba(96, 96, 96, 0.7)' },
  { type: 'color', value: 'rgba(97, 97, 97, 1)' },
  { type: 'color', value: 'rgba(255, 255, 255, 1)' },
  { type: 'color', value: 'rgba(215, 81, 175, 1)' },
  { type: 'color', value: 'rgba(208, 208, 208, 1)' },
  { type: 'color', value: 'rgba(67, 165, 210, 1)' },
  { type: 'color', value: 'rgba(138, 138, 138, 1)' },
  { type: 'color', value: 'rgba(112, 255, 152, 0.7)' },
  { type: 'color', value: 'rgba(208, 208, 208, 1)' },
  { type: 'color', value: 'rgba(67, 165, 210, 1)' },
  { type: 'color', value: 'rgba(138, 138, 138, 1)' },
  { type: 'color', value: 'rgba(138, 138, 138, 1)' },
  { type: 'color', value: 'rgba(112, 255, 152, 0.7)' },
  { type: 'color', value: 'rgba(208, 208, 208, 1)' },
  { type: 'color', value: 'rgba(67, 165, 210, 1)' },
  { type: 'color', value: 'rgba(138, 138, 138, 1)' }
];

export const gradientExamples = [
  { type: 'gradient', value: ['rgb(0, 231, 247)', 'rgb(0, 194, 241)'] },
  { type: 'gradient', value: ['rgb(206, 159, 252)', 'rgb(115, 103, 240)'] },
  { type: 'gradient', value: ['rgb(211, 204, 227)', 'rgb(233, 228, 240)'] },
  { type: 'gradient', value: ['rgb(255, 106, 40)', 'rgb(254, 47, 87)'] },
  { type: 'gradient', value: ['rgb(255, 148, 21)', 'rgb(255, 199, 9)'] },
  { type: 'gradient', value: ['rgb(192, 164, 250)', 'rgb(217, 202, 248)'] },
  { type: 'gradient', value: ['rgb(222, 221, 240)', 'rgb(182, 188, 242)'] },
  { type: 'gradient', value: ['rgb(255, 153, 102)', 'rgb(255, 94, 98)'] },
  { type: 'gradient', value: ['rgb(255, 0, 131)', 'rgb(244, 100, 254)'] },
  { type: 'gradient', value: ['rgb(45, 189, 232)', 'rgb(0, 112, 165)'] },
  { type: 'gradient', value: ['rgb(255, 207, 197)', 'rgb(242, 243, 188)'] },
  { type: 'gradient', value: ['rgb(4, 206, 155)', 'rgb(100, 228, 8)'] },
  { type: 'gradient', value: ['rgb(142, 45, 226)', 'rgb(74, 0, 224)'] },
  { type: 'gradient', value: ['rgb(0, 242, 254)', 'rgb(79, 172, 254)'] },
  { type: 'gradient', value: ['rgb(32, 228, 164)', 'rgb(44, 201, 204)'] },
  { type: 'gradient', value: ['rgb(127, 0, 255)', 'rgb(225, 0, 255)'] },
  { type: 'gradient', value: ['rgb(149, 216, 247)', 'rgb(68, 145, 255)'] },
  { type: 'gradient', value: ['rgb(109, 16, 126)', 'rgb(240, 51, 88)'] },
  { type: 'gradient', value: ['rgb(168, 255, 120)', 'rgb(120, 255, 214)'] },
  { type: 'gradient', value: ['rgb(255, 0, 204)', 'rgb(51, 51, 153)'] },
  { type: 'gradient', value: ['rgb(131, 77, 155)', 'rgb(208, 78, 214)'] },
  { type: 'gradient', value: ['rgb(243, 186, 227)', 'rgb(156, 186, 237)'] },
  { type: 'gradient', value: ['rgb(31, 165, 255)', 'rgb(16, 83, 255)'] },
  { type: 'gradient', value: ['rgb(27, 103, 232)', 'rgb(98, 9, 195)'] },
  { type: 'gradient', value: ['rgb(32, 108, 235)', 'rgb(47, 1, 102)'] },
  { type: 'gradient', value: ['rgb(236, 0, 140)', 'rgb(252, 103, 103)'] },
  { type: 'gradient', value: ['rgb(100, 200, 189)', 'rgb(9, 74, 111)'] },
  { type: 'gradient', value: ['rgb(53, 200, 203)', 'rgb(123, 45, 214)'] },
  { type: 'gradient', value: ['rgb(111, 134, 214)', 'rgb(72, 198, 239)'] },
  { type: 'gradient', value: ['rgb(9, 32, 63)', 'rgb(83, 120, 149)'] },
  { type: 'gradient', value: ['rgb(96, 108, 136)', 'rgb(63, 76, 107)'] },
  { type: 'gradient', value: ['rgb(11, 163, 96)', 'rgb(60, 186, 146)'] }
];

interface Props {
  type: 'color' | 'gradient';
  hex?: ColorResult['hex'];
  hsl?: ColorResult['hsl'];
  rgb?: ColorResult['rgb'];
  onChangeGradient?: (params: Array<string>) => void;
}

interface State {
  mode: string;
  index: number;
}

type ColorType = 'hex' | 'rgb' | 'hsl';

const hueStyles = {
  slider: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    width: '214px'
  }
};

const alphaStyles = {
  slider: {
    border: '1px solid #E0E0E0',
    width: '214px'
  }
};

const CustomPointerSaturation = () => <div className={b('pointer', { saturation: true })} />;
const CustomPointer = () => <div className={b('pointer', { slider: true })} />;

export const Picker = CustomPicker<Props>((props) => {
  const [state, setState] = useState<State>({
    mode: 'hex',
    index: 0
  });

  const { t } = useTranslation();

  const [colorsState, setColorsState] = useState({
    hsl: props.hsl,
    rgb: props.rgb,
    hex: props.hex
  });

  useEffect(() => {
    setColorsState({
      rgb: props.rgb,
      hsl: props.hsl,
      hex: props.hex
    });
  }, [props]);

  const handleChange = (color: any) => {
    if (props.onChange) {
      props.onChange(color);
    }
  };

  const handleChangeGradient = (colors: Array<string>) => {
    if (props.onChangeGradient) {
      props.onChangeGradient(colors);
    }
  };

  const handleChangeEdit = (e: any, type: string) => {
    const value = e.target.value;

    switch (type) {
      case 'hex':
        setColorsState(() => ({
          ...colorsState,
          hex: !value.includes('#') ? `#${value}` : value
        }));
        break;
    }
  };

  const applyColor = (type: ColorType) => {
    if (props.onChange) {
      // @ts-ignore
      props.onChange({ [type]: colorsState[type] });
    }
  };

  const handleToggleMode = () => {
    const newIndex = modes.length === state.index + 1 ? 0 : state.index + 1;

    setState({ ...state, mode: modes[newIndex], index: newIndex });
  };

  return (
    <div className={b()}>
      <div className={b('saturation')}>
        <Saturation {...props} pointer={CustomPointerSaturation} onChange={handleChange} />
      </div>
      <div className={b('hue')}>
        <Hue {...props} pointer={CustomPointer} styles={hueStyles} onChange={handleChange} />
      </div>
      <div className={b('alpha')}>
        <Alpha {...props} pointer={CustomPointer} styles={alphaStyles} onChange={handleChange} />
      </div>
      <div className={b('input')}>
        <button className={b('mode')} onClick={handleToggleMode}>
          <span className={b('modeVal')}>{state.mode.toUpperCase()}</span>
          <Icon className={b('modeValIcon').toString()} name="chevron-down" variant="editor" />
        </button>
        {state.mode === 'hex' && (
          <input
            className={b('inputItem')}
            type="text"
            value={colorsState.hex?.toUpperCase().replace('#', '')}
            onBlur={() => applyColor('hex')}
            onChange={(e) => handleChangeEdit(e, 'hex')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                applyColor('hex');
              }
            }}
          />
        )}
        {state.mode === 'rgb' && (
          <>
            <input className={b('inputItem')} readOnly type="text" value={colorsState.rgb?.r} />
            <input className={b('inputItem')} readOnly type="text" value={colorsState.rgb?.g} />
            <input className={b('inputItem')} readOnly type="text" value={colorsState.rgb?.b} />
            <input
              className={b('inputItem', { wide: true })}
              readOnly
              type="text"
              value={`${roundToPercent(colorsState.rgb?.a)}%`}
            />
          </>
        )}
        {state.mode === 'hsl' && (
          <>
            <input
              className={b('inputItem')}
              readOnly
              type="text"
              value={props.hsl?.h ? Math.round(props.hsl?.h) : 0}
            />
            <input
              className={b('inputItem')}
              readOnly
              type="text"
              value={roundToPercent(props.hsl?.s)}
            />
            <input
              className={b('inputItem')}
              readOnly
              type="text"
              value={roundToPercent(props.hsl?.l)}
            />
            <input
              className={b('inputItem', { wide: true })}
              readOnly
              type="text"
              value={`${roundToPercent(props.hsl?.a)}%`}
            />
          </>
        )}
      </div>

      <div className={b('colors')}>
        <p className={b('colorsTitle')}> {t('editor.colors')}</p>
        <div className={b('colorsWrapper')}>
          <SimpleBar
            classNames={{
              scrollContent: b('colorsContainer')
            }}
            style={{ maxHeight: 47 }}
          >
            {props.type === 'color' ? (
              <>
                {colorExamples.map((color, index) => (
                  <ColorExample
                    color={color.value}
                    index={index}
                    key={`color-exp-${index}`}
                    onClick={(value) => handleChange(value)}
                  />
                ))}
              </>
            ) : (
              <>
                {gradientExamples.map((colors, index) => (
                  <ColorExampleGradient
                    colors={colors.value}
                    index={index}
                    key={`colors-exp-${index}`}
                    onClick={(value) => handleChangeGradient(value)}
                  />
                ))}
              </>
            )}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
});
