import { WidgetObjectType } from '@storysdk/react';
import { GroupPositionType, SaveStatus, StoryLayers, StoryStatus } from '.';

export enum EditorMode {
  DESIGN = 'design',
  LOGIC = 'logic'
}

export enum EditorStoriesMode {
  STORIES = 'stories',
  TEMPLATES = 'templates'
}

export type ChangedWidgetType = {
  widgetId: string;
  saveStatus: SaveStatus;
};

export type ChangedObjectsType = {
  id: string;
  layersGroupId: string;
  storyId: string;
  widgetId?: string;
  saveStatus: SaveStatus;
  syncToLocales?: boolean;
};
export interface StoriesState {
  stories: StoryLayers;
  editor: {
    widgetsBuffer: { storyId: string | null; widgets: WidgetObjectType[] };
    selectedLayersGroupId: string | null;
    selectedWidgetIds: string[];
    group: GroupPositionType | null;
    isPickerOpen: boolean;
    isPickerStoryOpen: boolean;
    openTab?: {
      tab: string;
      storyId: string;
    };
    keepRatio: boolean;
    changedObjects: ChangedObjectsType[];
    editorMode: EditorMode;
    scheduleLayersGroupId: string | null;
    shareStoryId: string | null;
    lastPublishedStoryId: string | null;
  };
  filter: 'all' | 'draft' | 'active' | StoryStatus | string;
  status: 'idle' | 'pending' | 'loaded';
  creatingStatus: 'notcreated' | 'creating' | 'created';
  savingStatus: 'saved' | 'saving' | 'error';
  isFirstLoad?: boolean;
}
